<template>
  <v-menu class="shadow-1">
    <template v-slot:activator="{ on: menu, attrs }">
      <v-chip
        :color="priorityDetails.color"
        dark
        v-bind="attrs"
        v-on="{ ...menu }"
        :loading="loading"
        small
        class="mx-1"
      >
        <v-icon
          size="20px"
          :style="{
            color: `${
              isDark(priorityDetails.color) ? 'white' : 'black'
            } !important`,
          }"
          >mdi mdi-flag-variant-outline</v-icon
        >
        <span
          :style="{
            color: `${
              isDark(priorityDetails.color) ? 'white' : 'black'
            } !important`,
            fontWeight: 600,
          }"
        >
          {{ priorityDetails.name }}
        </span>
      </v-chip>
    </template>
    <v-list dense flat>
      <v-list-item
        v-for="(priority, index) in priorities"
        :key="index"
        class="px-2 cursor-pointer"
        @click="$emit('setPriority', priority.id)"
      >
        <v-list-item-title
          :style="{
            color: `${isDark(priority.color) ? 'white' : 'black'} !important`,
            backgroundColor: `${priority.color}`,
          }"
          class="py-2 px-5 text-center"
        >
          {{ priority.name }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        class="px-2 cursor-pointer"
        @click="$emit('setPriority', null)"
      >
        <v-list-item-title
          :style="{
            backgroundColor: '#f5f5f5',
          }"
          class="py-2 px-5 text-center"
        >
          Clear
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapState } from "vuex";
import { isDark } from "../../../helper/uttils";

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    priorityId: {
      type: [Number, null],
      default: null,
    },
  },
  components: {},
  computed: {
    ...mapState("tasks", ["priorities"]),
    priorityDetails() {
      const priorityDetails = this.priorities.find(
        (priority) => priority.id == this.priorityId
      );

      return (
        priorityDetails || {
          name: "Set Priority",
          color: "#dddae6",
          id: 0,
        }
      );
    },
  },
  methods: {
    isDark(color) {
      return isDark(color);
    },
  },
};
</script>
