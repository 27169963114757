<template>
  <svg :width="size" :height="size" :viewBox="`0 0 24 24`">
  <path d="M22.9335 11.3704C22.9017 11.2973 22.1217 9.5676 20.3798 7.82572C18.7635 6.21135 15.9867 4.28572 11.9198 4.28572C7.85291 4.28572 5.07603 6.21135 3.45978 7.82572C1.71791 9.5676 0.93791 11.2945 0.906035 11.3704C0.873984 11.4424 0.857422 11.5203 0.857422 11.5992C0.857422 11.678 0.873984 11.7559 0.906035 11.8279C0.93791 11.9001 1.71791 13.6298 3.45978 15.3717C5.07603 16.986 7.85291 18.9107 11.9198 18.9107C15.9867 18.9107 18.7635 16.986 20.3798 15.3717C22.1217 13.6298 22.9017 11.9029 22.9335 11.8279C22.9656 11.7559 22.9821 11.678 22.9821 11.5992C22.9821 11.5203 22.9656 11.4424 22.9335 11.3704ZM11.9198 17.7857C8.97791 17.7857 6.40916 16.7151 4.28385 14.6048C3.39292 13.7194 2.639 12.706 2.04697 11.5982C2.63883 10.4906 3.39277 9.47754 4.28385 8.5926C6.40916 6.48135 8.97791 5.41072 11.9198 5.41072C14.8617 5.41072 17.4304 6.48135 19.5557 8.5926C20.4468 9.47754 21.2007 10.4906 21.7926 11.5982C21.1954 12.7429 18.201 17.7857 11.9198 17.7857ZM11.9198 7.28572C11.0669 7.28572 10.2331 7.53864 9.52389 8.01251C8.8147 8.48637 8.26196 9.15989 7.93555 9.9479C7.60915 10.7359 7.52375 11.603 7.69015 12.4395C7.85655 13.2761 8.26727 14.0445 8.87039 14.6476C9.4735 15.2507 10.2419 15.6615 11.0785 15.8279C11.915 15.9943 12.7821 15.9089 13.5701 15.5825C14.3581 15.256 15.0316 14.7033 15.5055 13.9941C15.9794 13.2849 16.2323 12.4512 16.2323 11.5982C16.2308 10.4549 15.776 9.3589 14.9675 8.55047C14.1591 7.74204 13.0631 7.28721 11.9198 7.28572ZM11.9198 14.7857C11.2894 14.7857 10.6731 14.5988 10.1489 14.2485C9.62472 13.8983 9.21617 13.4005 8.97492 12.818C8.73366 12.2356 8.67054 11.5947 8.79353 10.9764C8.91652 10.3581 9.2201 9.7901 9.66588 9.34432C10.1117 8.89854 10.6796 8.59496 11.2979 8.47197C11.9162 8.34898 12.5571 8.4121 13.1396 8.65335C13.722 8.89461 14.2198 9.30316 14.5701 9.82734C14.9203 10.3515 15.1073 10.9678 15.1073 11.5982C15.1073 12.4436 14.7715 13.2544 14.1737 13.8521C13.5759 14.4499 12.7652 14.7857 11.9198 14.7857Z" :fill="color"/>
  </svg>
</template>

<script>
import IconMixin from "../../mixins/IconMixin"

export default {
  mixins: [IconMixin]
};
</script>
