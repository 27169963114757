<template>
  <div class="status-tasks-container">
    <v-expansion-panel-header hide-actions class="pa-0 panel-header">
      <template v-slot="{ open }">
        <div class="header">
          <div class="status-title-container">
            <div
              :class="{ 'status-title': true, 'status-title-expanded': open }"
            >
              <v-icon
                size="18"
                :style="{ backgroundColor: color }"
                color="white"
                class="mr-1"
                @click="open = !open"
                style="transition: 0.5s; border-radius: 20px"
              >
                {{ open ? "mdi mdi-chevron-up" : "mdi mdi-chevron-down" }}
              </v-icon>
              {{ name }}
            </div>
            <span class="status-subtitle">{{ tasks.length }} Tasks</span>
          </div>
          <div>Assignees</div>
          <div>Due Date</div>
          <div>Priority</div>
          <div>Actions</div>
        </div>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="content-container">
      <div v-if="showNoTasks" class="grey--text body-2 no-tasks pl-9">
        No Tasks Available
      </div>
      <div v-for="task in tasks" :key="task.task_id">
        <div class="content">
          <div
            class="d-flex align-center mr-3"
            :style="`border-left: 3px solid ${color}`"
          >
            <v-icon class="ml-4 mr-3" :color="color" size="10">
              mdi-circle
            </v-icon>
            <span
              style="cursor: pointer"
              @click.stop="
                () => $emit('openTaskDetailsDialog', task.task_id, task.list_id)
              "
            >
              {{ task.name }}
            </span>
            <div class="border-1 border-radius-6 d-flex align-center ml-3">
              <v-btn
                class="elevation-0"
                color="white"
                small
                @click="hideShowSubTasks(task.task_id)"
              >
                <img
                  src="@/assets/icons/SubTaskIcon.svg"
                  height="14px"
                  width="14px"
                  class="mx-2"
                />
                <span
                  class="text--secondary mr-2"
                  v-if="task.sub_tasks.length"
                  >{{ task.sub_tasks.length }}</span
                >
              </v-btn>
              <v-btn
                icon
                depressed
                small
                @click="openNewTaskSection(task.task_id)"
                :disabled="isSavingNewTaskDetails"
              >
                <v-icon
                  color="secondary"
                  size="16px"
                  class="pa-1"
                  style="border-left: 1px solid var(--border-color)"
                >
                  mdi-plus
                </v-icon>
              </v-btn>
            </div>

            <div class="ml-3 d-flex align-center">
              <v-menu offset-y min-width="250px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon small v-bind="attrs" v-on="on">
                    <v-icon>mdi-tag-multiple-outline</v-icon>
                  </v-btn>
                </template>
                <div class="white">
                  <v-text-field
                    v-model="newTag.name"
                    @click.native.stop
                    dense
                    hide-details
                    solo
                    flat
                    placeholder="Add New Tag"
                    class="subtitle-2 py-1"
                  >
                    <template v-slot:prepend-inner>
                      <v-menu
                        top
                        nudge-bottom="300"
                        nudge-left="16"
                        :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ on }">
                          <div :style="swatchStyle" v-on="on"></div>
                        </template>
                        <v-card>
                          <v-card-text class="pa-0">
                            <v-color-picker v-model="newTag.color" flat />
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </template>
                    <template v-slot:append>
                      <v-btn
                        @click="storeNewTag()"
                        color="primary"
                        x-small
                        :style="{ marginTop: '4px', backgroundColor: 'white' }"
                      >
                        save
                      </v-btn>
                    </template>
                  </v-text-field>
                  <v-divider class="#878DAB" />
                  <v-list>
                    <v-list-item
                      v-for="tags in projectTags"
                      :key="tags.id"
                      style="min-height: 30px; max-height: 40px"
                    >
                      <v-list-item-icon style="margin: 8px 16px 8px 0">
                        <v-icon :color="tags.color">mdi-tag-multiple</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content
                        @click="addTaskTag(task.task_id, tags.id)"
                      >
                        <v-list-item-title class="cursor-pointer">
                          {{ tags.name }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn x-small icon @click="removeProjectTags(tags.id)">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item v-if="projectTags.length == 0">
                      No Tags
                    </v-list-item>
                  </v-list>
                </div>
              </v-menu>
            </div>

            <div
              v-for="taskTag in task.task_tags"
              :key="taskTag.id"
              class="ml-2"
            >
              <span
                class="d-flex align-center"
                :style="{
                  backgroundColor: taskTag.projectTags.color,
                  padding: '1px 6px',
                  borderRadius: '5px',
                  fontWeight: '500',
                  fontSize: '12px',
                  color: invertColor(taskTag.projectTags.color),
                }"
                >{{ taskTag.projectTags.name }}
                <v-btn
                  icon
                  width="14px"
                  height="14px"
                  class="ml-3"
                  style="background-color: white"
                  @click="removeTaskTags(taskTag.id, task.task_id)"
                >
                  <v-icon style="font-size: 14px">mdi-close</v-icon>
                </v-btn>
              </span>
            </div>
          </div>
          <div class="d-flex align-center">
            <div class="avatars-wrapper">
              <InteractiveAvatar
                v-for="assignee in task.assignees"
                :key="assignee.employee?.id"
                :size="'30px'"
                :color="assignee.employee?.color"
                :intials="assignee.employee?.intials"
                :profile_url="assignee.employee?.profile_url"
                :name="assignee.employee?.name"
                @removeAssignee="
                  removeAssignee(task.task_id, assignee.employee?.id)
                "
              />
            </div>
            <v-menu
              v-model="task.showAssigneeMenu"
              @input="
                (isOpen) => handleAssigneeMenuToggle(task.assignees, isOpen)
              "
              nudge-width="250"
              max-width="250"
              v-if="task.assignees.length != projectTeamMembers.length"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <div>
                  <v-img
                    src="../../../assets/icons/AddAssigneeIcon.svg"
                    max-width="30"
                    max-height="30"
                    width="30"
                    height="30"
                    :class="{
                      'cursor-pointer': true,
                      'ml-2': task.assignees.length,
                    }"
                    v-bind="attrs"
                    v-on="on"
                    v-show="!addingAssigneeForTaskIds[task.task_id]"
                  />
                  <v-progress-circular
                    v-show="!!addingAssigneeForTaskIds[task.task_id]"
                    indeterminate
                    color="primary"
                    size="30"
                  />
                </div>
              </template>
              <div>
                <v-text-field
                  v-model="searchTeamMember"
                  solo
                  flat
                  placeholder="Search..."
                  dense
                  hide-details=""
                  class="py-1 white"
                  prepend-inner-icon="mdi-magnify"
                  @input="filterAssignee(task.assignees)"
                ></v-text-field>
                <v-divider class="#878DAB" />
                <v-list v-if="filteredTeamMembers.length">
                  <v-list-item
                    v-for="(teamMember, index) in filteredTeamMembers"
                    :key="index"
                    class="cursor-pointer"
                    @click="
                      addAssignee(task.task_id, teamMember.employee?.id);
                      task.showAssigneeMenu = false;
                    "
                  >
                    <v-list-item-avatar>
                      <Avatar
                        size="30px"
                        :color="teamMember.employee?.color"
                        :intials="teamMember.employee?.intials"
                        :profile_url="teamMember.employee?.profile_url"
                      />
                    </v-list-item-avatar>
                    <v-list-item-title class="text-truncate">
                      {{ teamMember.employee?.name }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
                <div class="grey--text body-2 no-tasks px-2 text-center" v-else>
                  No Members Found
                </div>
              </div>
            </v-menu>
          </div>
          <div>
            <v-menu
              :ref="'menu' + task.task_id"
              v-model="menu[task.task_id]"
              :close-on-content-click="false"
              :return-value.sync="task.due_date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon small v-bind="attrs" v-on="on">
                  <img width="19px" src="@/assets/icons/due_data.svg" />
                </v-btn>
                <!-- <v-text-field v-model="task.due_date" label="Picker in menu" prepend-icon="mdi-calendar" readonly
                  v-bind="attrs" v-on="on"></v-text-field> -->
              </template>
              <v-date-picker v-model="task.due_date" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu[task.task_id] = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="saveDueData(task.task_id, task.due_date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>

            <span class="red--text body-2 ml-2">{{
              task.due_date
                ? humanReadableDate(task.due_date, "DD-MM-YYYY")
                : ""
            }}</span>
          </div>
          <div>
            <PrioritySelector
              @setPriority="
                (priorityId) => setTaskPriority(priorityId, task.task_id)
              "
              :priorityId="task.task_priority || null"
              :loading="updatingPrioritiesForTaskIds[task.task_id]"
            />
          </div>
          <div>
            <span
              @click.stop="
                () => $emit('openTaskDetailsDialog', task.task_id, task.list_id)
              "
            >
              <EyeIcon :size="20" class="cursor-pointer mr-3" />
            </span>
            <span @click.stop="() => $emit('deleteTask', task.task_id)">
              <TrashIcon :size="19" class="cursor-pointer" />
            </span>
          </div>
        </div>
        <div class="mb-1" v-if="showSubTasks[task.task_id]">
          <div class="content" v-for="(subTask, i) in task.sub_tasks" :key="i">
            <div class="d-flex align-center">
              <v-icon size="10" class="ml-9">mdi-circle-outline</v-icon>
              <v-card
                block
                class="px-4"
                elevation="0"
                @click.stop="
                  () =>
                    $emit(
                      'openTaskDetailsDialog',
                      subTask.task_id,
                      subTask.list_id
                    )
                "
              >
                <span>{{ subTask.name }}</span>
              </v-card>
            </div>
            <div class="d-flex align-center">
              <div class="avatars-wrapper">
                <InteractiveAvatar
                  v-for="assignee in subTask.assignees"
                  :key="assignee.employee?.id"
                  :size="'30px'"
                  :color="assignee.employee?.color"
                  :intials="assignee.employee?.intials"
                  :profile_url="assignee.employee?.profile_url"
                  :name="assignee.employee?.name"
                  @removeAssignee="
                    removeSubTaskAssignee(
                      task.task_id,
                      subTask.task_id,
                      assignee.employee?.id
                    )
                  "
                />
              </div>

              <v-menu offset-y min-width="250px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="grey"
                    dark
                    outlined
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    class="mt-0"
                    style="margin-left: 10px; margin-top: 2px"
                    @click="filterSubTaskMember(subTask, true)"
                  >
                    <v-img
                      src="../../../assets/icons/AddAssigneeIcon.svg"
                      max-width="32"
                      max-height="32"
                      width="32"
                      height="32"
                    />
                  </v-btn>
                </template>
                <div class="white">
                  <v-text-field
                    @click.native.stop
                    v-model="subTaskAssigneeSearch"
                    prepend-inner-icon="mdi-magnify"
                    class="py-1 white"
                    solo
                    flat
                    @input="debouncedSubTaskAssignee(subTask)"
                    placeholder="Search..."
                    hide-details=""
                    dense
                  ></v-text-field>
                  <v-divider class="#878DAB" />
                  <v-list>
                    <v-list-item
                      v-for="member in filterSubTaskMembers"
                      :key="member.id"
                    >
                      <v-list-item-avatar>
                        <!-- <v-img :src="member.employee.profile_url" width="30px"></v-img> -->
                        <Avatar
                          size="30px"
                          :color="member.employee?.color"
                          :intials="member.employee?.intials"
                          :profile_url="member.employee?.profile_url"
                        />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          class="cursor-pointer"
                          @click="
                            saveSubTaskAssignee(
                              member.employee.id,
                              task.task_id,
                              subTask.task_id
                            )
                          "
                        >
                          {{ member.employee.name }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="filterSubTaskMembers.length == 0">
                      No Assignees
                    </v-list-item>
                  </v-list>
                </div>
              </v-menu>
            </div>
          </div>
        </div>
        <div
          class="new-task-container"
          v-if="task.task_id == newTask.parent_id"
        >
          <v-icon size="10" class="ml-9">mdi-circle-outline</v-icon>
          <v-text-field
            v-model="newTask.name"
            placeholder="Task"
            class="no-border body-2"
            solo
            flat
            dense
            hide-details
          />
          <div class="actions">
            <div class="avatars-wrapper">
              <InteractiveAvatar
                v-for="assignee in newTask.assignees"
                :key="assignee.employee?.id"
                :size="'30px'"
                :color="assignee.employee?.color"
                :intials="assignee.employee?.intials"
                :profile_url="assignee.employee?.profile_url"
                :name="assignee.employee?.name"
                @removeAssignee="removeAssigneeFromNewTask(assignee)"
              />
            </div>
            <v-menu
              @input="
                (isOpen) => handleAssigneeMenuToggle(newTask.assignees, isOpen)
              "
              nudge-width="250"
              max-width="250"
              v-if="newTask.assignees.length != projectTeamMembers.length"
            >
              <template v-slot:activator="{ on, attrs }">
                <div>
                  <v-img
                    src="../../../assets/icons/AddAssigneeIcon.svg"
                    max-width="30"
                    max-height="30"
                    width="30"
                    height="30"
                    class="cursor-pointer"
                    v-bind="attrs"
                    v-on="on"
                  />
                </div>
              </template>
              <div>
                <v-list v-if="filteredTeamMembers.length">
                  <v-list-item
                    v-for="(teamMember, index) in filteredTeamMembers"
                    :key="index"
                    class="cursor-pointer"
                    @click="addAssigneeInNewTask(teamMember)"
                  >
                    <v-list-item-avatar>
                      <Avatar
                        size="30px"
                        :color="teamMember.employee?.color"
                        :intials="teamMember.employee?.intials"
                        :profile_url="teamMember.employee?.profile_url"
                      />
                    </v-list-item-avatar>
                    <v-list-item-title class="text-truncate">
                      {{ teamMember.employee?.name }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
                <div class="grey--text body-2 no-tasks px-2 text-center" v-else>
                  No Members Found
                </div>
              </div>
            </v-menu>
            <PrioritySelector
              @setPriority="
                (priorityId) => (newTask.task_priority = priorityId)
              "
              :priorityId="newTask.task_priority || null"
            />
            <v-btn
              color="primary"
              depressed
              dense
              small
              @click.stop="saveNewTask"
              :loading="isSavingNewTaskDetails"
            >
              Save
            </v-btn>
            <v-btn
              icon
              depressed
              small
              :disabled="isSavingNewTaskDetails"
              @click="closeNewTaskSection"
            >
              <v-icon color="secondary"> mdi-close </v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </v-expansion-panel-content>
    <v-btn
      depressed
      text
      color="secondary"
      class="caption mt-2"
      @click.stop="() => $emit('openAddEditTaskDialog', null, id)"
    >
      <v-icon class="mr-1" size="14">mdi-plus</v-icon>New Task
    </v-btn>
    <v-snackbar
      v-model="alert.show"
      :timeout="3000"
      :color="alert.color"
      align-baseline="center"
    >
      {{ alert.message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="alert.show = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import InteractiveAvatar from "../../common/basic/InteractiveAvatar.vue";
import PrioritySelector from "../basic/PrioritySelector.vue";
import { mapState, mapMutations } from "vuex";
import Avatar from "../basic/Avatar.vue";
import moment from "moment";
import TrashIcon from "../../../assets/icons/TrashIcon.vue";
import EyeIcon from "../../../assets/icons/EyeIcon.vue";
import { cloneDeep } from "lodash";
import { debounce } from "lodash";

const defaultTaskValue = {
  name: "",
  task_priority: "",
  assignees: [],
  parent_id: "",
};

export default {
  props: {
    id: {
      type: Number,
      require: true,
    },
    clickup_id: [String, Number],
    folder_id: {
      type: Number,
      require: true,
    },
    list_id: {
      type: [Number, String],
      require: true,
    },
    name: {
      type: String,
      require: true,
    },
    type: String,
    order_index: Number,
    color: String,
    tasks: {
      type: Array,
      require: false,
      default: () => [],
    },
  },
  data() {
    return {
      updatingPrioritiesForTaskIds: {},
      addingAssigneeForTaskIds: {},
      filteredTeamMembers: [],
      filteredSubTaskTeamMembers: [],
      alert: {
        show: false,
        message: "",
        color: "",
      },
      newTask: cloneDeep(defaultTaskValue),
      isSavingNewTaskDetails: false,
      errors: {},
      searchTeamMember: "",
      showSubTasks: {},
      viewSubTask: false,
      subTaskParentId: null,
      filterSubTaskMembers: [],
      subTaskAssigneeSearch: "",
      selectedSubTask: "",
      menu: {},
      taskTags: [],
      newTag: {
        name: null,
        color: "#006EFF",
        project_id: null,
      },
    };
  },
  components: {
    InteractiveAvatar,
    PrioritySelector,
    Avatar,
    TrashIcon,
    EyeIcon,
  },
  computed: {
    ...mapState("projects", [
      "projectTeamMembers",
      "projectTags",
      "cachesTags",
    ]),
    ...mapState("tasks", ["tasksByListStatus"]),
    showNoTasks() {
      return this.tasks.length == 0;
    },
    swatchStyle() {
      const { menu } = this;
      return {
        backgroundColor: this.newTag.color,
        cursor: "pointer",
        height: "15px",
        width: "15px",
        borderRadius: menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
        marginTop: "7px",
        marginRight: "3px",
      };
    },
  },
  methods: {
    ...mapMutations("tasks", ["setTasksByListStatus"]),
    ...mapMutations("projects", ["setProjectTags","updateProjectCaches"]),

    showAlertMessage(isSucessMsg, message) {
      this.alert = {
        message: message || "",
        color: isSucessMsg ? "green accent-4" : "red accent-2",
        show: true,
      };
    },
    saveDueData(task_id, date) {
      this.menu[task_id] = false;
      this.$axios
        .put(`set_due_date/${task_id}`, {
          due_date: date,
        })
        .then((response) => {
          const resData = response.data;

          if (resData.success) {
            let taskIndex = this.tasks.findIndex(
              (task) => task.task_id == task_id
            );

            if (taskIndex != -1) {
              let tempTasks = [...this.tasks];
              if (tempTasks[taskIndex]) {
                tempTasks = [...tempTasks];
                tempTasks[taskIndex].due_date = resData.task.due_date;
              }

              let statusIndex = this.tasksByListStatus.findIndex(
                (status) => status.id == this.id
              );

              if (statusIndex != -1) {
                let tempTasksByListStatus = [...this.tasksByListStatus];

                tempTasksByListStatus[statusIndex] = {
                  ...tempTasksByListStatus[statusIndex],
                  tasks: tempTasks,
                };

                this.setTasksByListStatus(tempTasksByListStatus);
              }
            }
          } else {
            this.showAlertMessage(
              false,
              resData.message || "Something went wrong"
            );
          }
        })
        .catch(() => {
          this.showAlertMessage(false, "Something went wrong");
        });
    },
    removeAssignee(task_id, employee_id) {
      this.$axios
        .post(`tasks/${task_id}/assignee/remove`, {
          employee_id: employee_id,
        })
        .then((response) => {
          const resData = response.data;

          if (resData.success) {
            // removing assignee from task
            let taskIndex = this.tasks.findIndex(
              (task) => task.task_id == task_id
            );

            if (taskIndex != -1) {
              let tempTasks = [...this.tasks];

              if (tempTasks[taskIndex] && tempTasks[taskIndex].assignees) {
                tempTasks[taskIndex].assignees = tempTasks[
                  taskIndex
                ].assignees.filter(
                  (assignee) => assignee.employee.id != employee_id
                );
              }

              let statusIndex = this.tasksByListStatus.findIndex(
                (status) => status.id == this.id
              );

              if (statusIndex != -1) {
                let tempTasksByListStatus = [...this.tasksByListStatus];

                tempTasksByListStatus[statusIndex] = {
                  ...tempTasksByListStatus[statusIndex],
                  tasks: tempTasks,
                };

                this.setTasksByListStatus(tempTasksByListStatus);
              }
            }
          } else {
            this.showAlertMessage(
              false,
              resData.message || "Something went wrong"
            );
          }
        })
        .catch(() => {
          this.showAlertMessage(false, "Something went wrong");
        });
    },
    removeSubTaskAssignee(task_id, subTask_id, employee_id) {
      this.$axios
        .post(`tasks/${subTask_id}/assignee/remove`, {
          employee_id: employee_id,
        })
        .then((response) => {
          const resData = response.data;

          if (resData.success) {
            // removing assignee from task
            let taskIndex = this.tasks.findIndex(
              (task) => task.task_id == task_id
            );

            let tempTasks = [...this.tasks];

            let taskData = tempTasks.filter((task) => {
              if (task.task_id == task_id) {
                return task;
              }
            });
            let subtasks = [];
            subtasks = taskData[0].sub_tasks;
            let subtaskIndex = subtasks.findIndex(
              (subtask) => subtask.task_id == subTask_id
            );

            if (taskIndex != -1) {
              if (subtasks[subtaskIndex] && subtasks[subtaskIndex].assignees) {
                subtasks[subtaskIndex].assignees = subtasks[
                  subtaskIndex
                ].assignees.filter(
                  (assignee) => assignee.employee.id != employee_id
                );
              }

              if (tempTasks[taskIndex] && tempTasks[taskIndex].assignees) {
                tempTasks[taskIndex].sub_tasks = subtasks;
              }

              let statusIndex = this.tasksByListStatus.findIndex(
                (status) => status.id == this.id
              );

              if (statusIndex != -1) {
                let tempTasksByListStatus = [...this.tasksByListStatus];

                tempTasksByListStatus[statusIndex] = {
                  ...tempTasksByListStatus[statusIndex],
                  tasks: tempTasks,
                };

                this.setTasksByListStatus(tempTasksByListStatus);
              }
            }
          } else {
            this.showAlertMessage(
              false,
              resData.message || "Something went wrong"
            );
          }
        })
        .catch(() => {
          this.showAlertMessage(false, "Something went wrong");
        });
    },
    filterSubTaskMember(subTask, fromTaskList = false) {
      this.subTaskAssigneeSearch = "";
      this.selectedSubTask = subTask.task_id;
      if (fromTaskList) {
        this.filterSubTaskMembers = this.projectTeamMembers.filter((member) => {
          return !subTask.assignees.find((assignee) => {
            return assignee.employee.id == member.employee.id;
          });
        });
      }
    },
    debouncedSubTaskAssignee: debounce(function (members) {
      const arrayColumn = (arr, n) => arr.map((x) => x[n]);
      let addAssigneelist = arrayColumn(members.assignees, "employee_id");
      this.filterSubTaskMembers = this.projectTeamMembers.filter((item) => {
        let empName = item.employee.name.toLocaleLowerCase();
        if (
          empName?.includes(this.subTaskAssigneeSearch?.toLocaleLowerCase()) &&
          !addAssigneelist.includes(item.employee_id)
        ) {
          return item;
        }
      });
    }, 500),
    saveSubTaskAssignee(employee_id, task_id, subTask_id) {
      let _self = this;

      _self.$axios
        .post("tasks/" + _self.selectedSubTask + "/assignee", {
          employee_id: employee_id,
          project_id: this.project_id,
        })
        .then((response) => {
          let data = response.data;
          _self.loading = false;

          if (data.success) {
            let statusIndex = this.tasksByListStatus.findIndex(
              (status) => status.id == this.id
            );
            let statusData = this.tasksByListStatus.filter((status) => {
              if (status.id == this.id) {
                return status;
              }
            });
            let tasks = [];
            tasks = statusData[0].tasks;
            let taskIndex = tasks.findIndex((task) => task.task_id == task_id);
            let taskData = tasks.filter((task) => {
              if (task.task_id == task_id) {
                return task;
              }
            });
            let subtasks = [];
            subtasks = taskData[0].sub_tasks;
            let subtaskIndex = subtasks.findIndex(
              (subtask) => subtask.task_id == subTask_id
            );

            if (taskIndex != -1) {
              if (subtasks[subtaskIndex] && subtasks[subtaskIndex].assignees) {
                subtasks[subtaskIndex].assignees = data.task?.assignees;
              }

              if (tasks[taskIndex] && tasks[taskIndex].assignees) {
                tasks[taskIndex].sub_tasks = subtasks;
              }
              if (statusIndex != -1) {
                let tempTasksByListStatus = [...this.tasksByListStatus];

                tempTasksByListStatus[statusIndex] = {
                  ...tempTasksByListStatus[statusIndex],
                  tasks: tasks,
                };

                this.setTasksByListStatus(tempTasksByListStatus);
              }
            }
          } else {
            _self.error = true;
            _self.message = data.message;
          }
        });
    },
    setTaskPriority(priorityId, task_id) {
      this.updatingPrioritiesForTaskIds = {
        ...this.updatingPrioritiesForTaskIds,
        [task_id]: true,
      };

      this.$axios
        .put(`task_priority/${task_id}`, {
          task_priority: priorityId || "",
        })
        .then((response) => {
          const resData = response.data;

          if (resData.success) {
            let tempTasks = [...this.tasks];

            // removing assignee from task
            let taskIndex = this.tasks.findIndex(
              (task) => task.task_id == task_id
            );

            if (taskIndex != -1) {
              tempTasks[taskIndex].task_priority = priorityId;
            }

            let statusIndex = this.tasksByListStatus.findIndex(
              (status) => status.id == this.id
            );

            if (statusIndex != -1) {
              let tempTasksByListStatus = [...this.tasksByListStatus];

              tempTasksByListStatus[statusIndex] = {
                ...tempTasksByListStatus[statusIndex],
                tasks: tempTasks,
              };

              this.setTasksByListStatus(tempTasksByListStatus);
            }
          } else {
            this.showAlertMessage(
              false,
              resData.message || "Something went wrong"
            );
          }
        })
        .catch(() => {
          this.showAlertMessage(false, "Something went wrong");
        })
        .finally(() => {
          this.updatingPrioritiesForTaskIds = {
            ...this.updatingPrioritiesForTaskIds,
            [task_id]: false,
          };
        });
    },
    addAssignee(task_id, employee_id) {
      this.addingAssigneeForTaskIds = {
        ...this.addingAssigneeForTaskIds,
        [task_id]: true,
      };

      this.$axios
        .post(`tasks/${task_id}/assignee`, {
          employee_id: employee_id,
        })
        .then((response) => {
          const resData = response.data;

          if (resData.success) {
            // adding assignee to the task
            let taskIndex = this.tasks.findIndex(
              (task) => task.task_id == task_id
            );

            if (taskIndex != -1) {
              let tempTasks = [...this.tasks];

              if (tempTasks[taskIndex] && tempTasks[taskIndex].assignees) {
                tempTasks[taskIndex].assignees = resData.task?.assignees;
              }

              let statusIndex = this.tasksByListStatus.findIndex(
                (status) => status.id == this.id
              );

              if (statusIndex != -1) {
                let tempTasksByListStatus = [...this.tasksByListStatus];

                tempTasksByListStatus[statusIndex] = {
                  ...tempTasksByListStatus[statusIndex],
                  tasks: tempTasks,
                };

                this.setTasksByListStatus(tempTasksByListStatus);
              }
            }
          } else {
            this.showAlertMessage(
              false,
              resData.message || "Something went wrong"
            );
          }
        })
        .catch(() => {
          this.showAlertMessage(false, "Something went wrong");
        })
        .finally(() => {
          this.addingAssigneeForTaskIds = {
            ...this.addingAssigneeForTaskIds,
            [task_id]: false,
          };
        });
    },
    filterAssignee(taskAssignees) {
      let assigneeIds = [];

      if (Array.isArray(taskAssignees)) {
        assigneeIds = taskAssignees.map((assignee) => assignee.employee_id);
      }

      const regExp = new RegExp(this.searchTeamMember.toLowerCase(), "ig");

      // get unassigned members
      this.filteredTeamMembers = this.projectTeamMembers.filter(
        (teamMember) => {
          let hasMatchingName = false;

          if (teamMember.employee && teamMember.employee.name) {
            hasMatchingName =
              teamMember.employee.name.toLowerCase().search(regExp) != -1;
          }

          return (
            !assigneeIds.includes(teamMember.employee_id) && hasMatchingName
          );
        }
      );
    },
    handleAssigneeMenuToggle(taskAssignees, isOpen) {
      this.filteredTeamMembers = [];
      this.searchTeamMember = "";

      let assigneeIds = [];

      if (Array.isArray(taskAssignees)) {
        assigneeIds = taskAssignees.map((assignee) => assignee.employee_id);
      }

      if (isOpen) {
        // get unassigned members
        this.filteredTeamMembers = this.projectTeamMembers.filter(
          (teamMember) => !assigneeIds.includes(teamMember.employee_id)
        );
      }
    },
    handleSubTaskAssigneeMenuToggle(subTasktaskAssignees, isOpen) {
      this.filteredSubTaskTeamMembers = [];
      this.searchTeamMember = "";

      let assigneeIds = [];

      if (Array.isArray(subTasktaskAssignees)) {
        assigneeIds = subTasktaskAssignees.map(
          (assignee) => assignee.employee_id
        );
      }

      if (isOpen) {
        // get unassigned members
        this.filteredSubTaskTeamMembers = this.projectTeamMembers.filter(
          (teamMember) => !assigneeIds.includes(teamMember.employee_id)
        );
      }
    },
    humanReadableDate(date, format) {
      return moment(date).format(format);
    },
    addAssigneeInNewTask(teamMember) {
      // check if employee is already assigned or not
      let isExist = this.newTask.assignees.find(
        (assignee) => assignee.employee_id == teamMember.employee_id
      );

      if (!isExist) {
        this.newTask.assignees.push(teamMember);
      }
    },
    removeAssigneeFromNewTask(teamMember) {
      this.newTask.assignees = this.newTask.assignees.filter(
        (assignee) => assignee.employee_id != teamMember.employee_id
      );
    },
    hideShowSubTasks(parentId) {
      this.closeNewTaskSection();
      this.viewSubTask = !this.viewSubTask;
      this.showSubTasks = {
        ...this.showSubTasks,
        [parentId]: !this.showSubTasks[parentId],
      };
    },
    openNewTaskSection(task_id) {
      this.showSubTasks = false;
      this.newTask = cloneDeep(defaultTaskValue);
      this.newTask = {
        ...this.newTask,
        parent_id: task_id,
      };
    },
    closeNewTaskSection() {
      this.newTask = cloneDeep(defaultTaskValue);
    },
    saveNewTask() {
      this.isSavingNewTaskDetails = true;

      const { name, task_priority, assignees, parent_id } = this.newTask;

      let formData = new FormData();

      formData.append("name", name);
      formData.append("list_id", this.list_id);
      formData.append("task_priority", task_priority);
      formData.append("parentTaskId", parent_id);
      formData.append("status_id", this.id);

      if (assignees.length > 0) {
        for (let j = 0; j < assignees.length; j++) {
          formData.append("assignees[]", assignees[j].employee?.id);
        }
      }

      this.$axios
        .post("tasks", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          const resData = response.data;

          if (resData.success) {
            this.showAlertMessage(true, resData.message);
          } else {
            if (resData.errors) {
              let errors = {};

              for (let i = 0; i < resData.errors.length; i++) {
                errors[resData.errors[i].field] = resData.errors[i].message;
              }

              this.errors = errors;
            } else {
              this.showAlertMessage(false, resData.message);
            }
          }
        })
        .catch(() => {
          this.showAlertMessage(false, "Something went wrong");
        })
        .finally(() => {
          this.isSavingNewTaskDetails = false;
          this.closeNewTaskSection();
        });
    },
    invertColor(hexcolor) {
      // If a leading # is provided, remove it
      if (hexcolor.slice(0, 1) === "#") {
        hexcolor = hexcolor.slice(1);
      }
      // Convert to RGB value
      var r = parseInt(hexcolor.substr(0, 2), 16);
      var g = parseInt(hexcolor.substr(2, 2), 16);
      var b = parseInt(hexcolor.substr(4, 2), 16);
      // Get YIQ ratio
      var yiq = (r * 299 + g * 587 + b * 114) / 1000;
      // Check contrast
      return yiq >= 128 ? "black" : "white";
    },
    storeNewTag() {
      let objdata = {
        name: this.newTag.name,
        color: this.newTag.color,
        project_id: this.project_id,
      };
      let _self = this;
      _self.$axios.post("add_new_tags", objdata).then((response) => {
        let data = response.data;
        if (data.success) {
          this.setProjectTags([...this.projectTags, data.tags]);
          this.loading = false;
          this.newTag.name = "";
          this.newTag.color = "#006EFF";
        } else {
          this.error = true;
          this.message = data.message ? data.message : data.errors[0].message;
          this.loading = false;
        }
      });
    },
    getProjectTags() {
      if (this.cachesTags) {
        return;
      }
      let _self = this;
      _self.$axios
        .get("get_project_tags/" + this.project_id)
        .then((response) => {
          let data = response.data;
          if (data.success) {
            this.setProjectTags(data.tags);
            this.updateProjectCaches({ key: "cachesTags", value: true });
          } else {
            this.error = true;
            this.message = data.message;
          }
        });
    },
    removeProjectTags(tagId) {
      this.loading = true;
      let _self = this;
      _self.$axios.delete("delete_project_tags/" + tagId).then((response) => {
        let data = response.data;
        if (data.success) {
          this.updateProjectCaches({ key: "cachesTags", value: false });
          this.getProjectTags();
          this.loading = false;
        } else {
          this.error = true;
          this.message = data.message;
          this.loading = false;
        }
      });
    },
    addTaskTag(taskId, projectTagId) {
      this.loading = true;
      let _self = this;
      _self.$axios
        .post("add_task_tags/" + projectTagId, { task_id: taskId })
        .then((response) => {
          let data = response.data;
          if (data.success) {
            // this.taskTags = data.tags
            this.loading = false;
            this.newTag.name = "";
            this.newTag.color = "#006EFF";

            let taskIndex = this.tasks.findIndex(
              (task) => task.task_id == taskId
            );

            if (taskIndex != -1) {
              let tempTasks = [...this.tasks];

              if (tempTasks[taskIndex] && tempTasks[taskIndex].task_tags) {
                tempTasks[taskIndex].task_tags.push(response.data.tags);
              }
              let statusIndex = this.tasksByListStatus.findIndex(
                (status) => status.id == this.id
              );

              if (statusIndex != -1) {
                let tempTasksByListStatus = [...this.tasksByListStatus];

                tempTasksByListStatus[statusIndex] = {
                  ...tempTasksByListStatus[statusIndex],
                  tasks: tempTasks,
                };

                this.setTasksByListStatus(tempTasksByListStatus);
              }
            }
          } else {
            this.error = true;
            this.message = data.message;
            this.loading = false;
          }
        });
    },
    removeTaskTags(tagId, taskId) {
      let _self = this;
      _self.$axios.delete("remove_task_tags/" + tagId).then((response) => {
        let data = response.data;
        if (data.success) {
          let taskIndex = this.tasks.findIndex(
            (task) => task.task_id == taskId
          );

          if (taskIndex != -1) {
            let tempTasks = [...this.tasks];

            if (tempTasks[taskIndex] && tempTasks[taskIndex].task_tags) {
              tempTasks[taskIndex].task_tags.filter((tags, index) => {
                if (tags.id == tagId) {
                  tempTasks[taskIndex].task_tags.splice(index, 1);
                }
              });
            }

            let statusIndex = this.tasksByListStatus.findIndex(
              (status) => status.id == this.id
            );

            if (statusIndex != -1) {
              let tempTasksByListStatus = [...this.tasksByListStatus];

              tempTasksByListStatus[statusIndex] = {
                ...tempTasksByListStatus[statusIndex],
                tasks: tempTasks,
              };

              this.setTasksByListStatus(tempTasksByListStatus);
            }
          }
        } else {
          this.error = true;
          this.message = data.message;
        }
      });
    },
    handleSocketAddTask(data) {
      this.tasks.push(data);
    },
    handleSocketAddAssignee(data) {
      this.tasks.map((item) => {
        if (item.task_id == data.task_id) {
          item.assignees = data.assignees;
        }
        return item;
      });
    },
    handleSocketAddPriority(data) {
      this.tasks.map((item) => {
        if (item.task_id == data.task_id) {
          item.task_priority = data.task_priority;
          item.taskpriority = data.taskpriority;
        }
        return item;
      });
    },
    handleSocketRemoveTask(task_id) {
      this.tasks = this.tasks.filter((item) => {
        if (item.task_id != Number(task_id)) {
          return item;
        }
      });
    },
    handleSocketChangeStatus(data) {
      let updateTask = this.tasksByListStatus?.map((item) => {
        if (item?.name == data.status) {
          item.tasks = item?.tasks?.map((task) => {
            if (task.task_id == data.task_id) {
              task = data;
            }
            return task;
          });
        }
        return item;
      });
      this.setTasksByListStatus(updateTask);
    },
  },
  mounted() {
    this.project_id = this.$route.params.id;
    this.$ws.$on("add_task", this.handleSocketAddTask);
    this.$ws.$on("add_assignee", this.handleSocketAddAssignee);
    this.$ws.$on("add_priority", this.handleSocketAddPriority);
    this.$ws.$on("remove_task", this.handleSocketRemoveTask);
    this.$ws.$on("update_task", this.handleSocketChangeStatus);
    this.getProjectTags();
  },
};
</script>

<style>
.avatars-wrapper {
  display: inline;
}

.avatars-wrapper .avatar-wrapper:not(:first-of-type) {
  transform: translateX(-10px);
}

.content-container .v-expansion-panel-content__wrap {
  padding: 0px !important;
}
</style>

<style scoped lang="scss">
.status-tasks-container {
  margin-bottom: 15px;
  background: var(--background-color);
}

.panel-header {
  min-height: 100% !important;
}

.header {
  color: var(--secondary-color);
  font-weight: medium;
  background: var(--background-color) !important;
}

.content {
  color: var(--primary-font-color);
  min-height: 45px;
  border-bottom: 2px solid var(--border-color);
  background: white;
}

.content:last-child {
  // border-radius: 0px 0px 6px 6px;
}

.header,
.content {
  display: grid;
  grid-template-columns: 5fr 2fr 1fr 1fr 1fr;
  align-items: center;
}

.status-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .status-title {
    border-radius: 6px;
    padding: 10px;
    display: inline-block;
    color: white;
    font-weight: normal;
    text-transform: capitalize;
    transition: 0.3s;
  }

  .status-title-expanded {
    border-radius: 6px 6px 0px 0px !important;
  }

  .status-subtitle {
    font-size: 11px;
    font-weight: medium;
    text-transform: uppercase;
    color: var(--secondary-color);
    margin-left: 10px;
  }
}

.no-tasks {
  border-radius: 0px 0px 6px 6px;
  background-color: white;
  padding: 15px 0px;
}

.new-task-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 0px;
  background: white;

  .actions {
    display: flex;
    align-items: center;
    gap: 15px;
  }
}
</style>
