<template>
  <svg :width="size" :height="size" :viewBox="`0 0 24 24`">
    <path
      d="M19.4232 3.71675C19.331 3.50265 19.1777 3.32047 18.9825 3.193C18.7874 3.06553 18.5589 2.99841 18.3258 3.00003H3.20082C2.96791 3.00047 2.74014 3.06852 2.54514 3.19589C2.35015 3.32327 2.19632 3.50451 2.10232 3.71761C2.00833 3.93072 1.97821 4.16652 2.01562 4.39641C2.05304 4.6263 2.15637 4.84038 2.31309 5.01268L2.31825 5.0187L8.13879 11.2328C8.16867 11.2648 8.18527 11.3069 8.1852 11.3506V17.9532C8.18529 18.1709 8.24448 18.3846 8.35646 18.5713C8.46844 18.7581 8.629 18.911 8.82103 19.0136C9.01307 19.1163 9.22937 19.1649 9.44687 19.1543C9.66437 19.1437 9.87492 19.0743 10.0561 18.9535L12.8061 17.1204C12.9708 17.0105 13.1059 16.8616 13.1993 16.687C13.2927 16.5123 13.3415 16.3173 13.3414 16.1192V11.3506C13.3414 11.3069 13.358 11.2648 13.3879 11.2328L19.2136 5.01268C19.372 4.8412 19.4764 4.62682 19.5137 4.39632C19.551 4.16583 19.5196 3.92945 19.4232 3.71675ZM18.4556 4.31315L12.635 10.5282C12.4258 10.7508 12.3096 11.045 12.3102 11.3506V16.1192C12.3103 16.1476 12.3034 16.1756 12.29 16.2006C12.2767 16.2257 12.2573 16.247 12.2337 16.2628L9.48371 18.0958C9.45785 18.1131 9.4278 18.123 9.39675 18.1245C9.3657 18.126 9.33482 18.1191 9.30739 18.1045C9.27996 18.0898 9.25702 18.068 9.24101 18.0414C9.22499 18.0147 9.21651 17.9842 9.21645 17.9532V11.3506C9.2167 11.0448 9.10056 10.7505 8.8916 10.5273L3.0745 4.31659C3.05283 4.29194 3.03868 4.26161 3.03371 4.22917C3.02874 4.19673 3.03315 4.16355 3.04644 4.13355C3.05973 4.10354 3.08132 4.07797 3.10868 4.05984C3.13604 4.04172 3.16801 4.03181 3.20082 4.03128H18.3258C18.3594 4.03009 18.3925 4.03927 18.4206 4.05757C18.4487 4.07587 18.4706 4.1024 18.4831 4.13354C18.4973 4.16273 18.5023 4.19555 18.4973 4.22764C18.4924 4.25972 18.4779 4.28955 18.4556 4.31315Z"
      :fill="color"
    />
  </svg>
</template>

<script>
import IconMixin from "../../mixins/IconMixin";

export default {
  mixins: [IconMixin],
};
</script>
