<template>
  <div>
    <div ref="quillEditor" class="quill-editor"></div>
  </div>
</template>

<script>
import Quill from "quill";
import "quill/dist/quill.snow.css"; // Import Quill's CSS

export default {
  name: "quill-editor",
  props: {
    editorId: {
      type: String,
      default: "editor-01",
    },
    value: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    type: {
      type: [Boolean, String],
      default: false,
    },
  },
  data() {
    return {
      quill: null,
      content: this.value, // Initialize with the passed value
    };
  },
  watch: {
    value(newValue) {
      // Update content if prop value changes
      this.content = newValue;
    },
  },
  mounted() {
    // Initialize Quill on mounted
    this.quill = new Quill(this.$refs.quillEditor, {
      theme: "snow", // You can choose 'bubble' or other themes as well
      modules: {
        toolbar: [
          ["bold", "italic", "underline"], // text formatting
          ["link", "image"], // insert link and image
          [{ list: "ordered" }, { list: "bullet" }], // lists
        ],
      },
    });

    // Set initial content
    this.quill.setContents(this.quill.clipboard.convert(this.content));

    // Handle change event
    this.quill.on("text-change", this.update);

    // Set read-only mode if needed
    if (this.readonly) {
      this.quill.enable(false); // Disable the editor
    }
  },
  methods: {
    update() {
      //   console.log(this.$refs.quillEditor.innerText, "refffffff");
      this.$emit("input", this.quill.root.innerHTML); // Emit the content back to the parent
    },
    reset() {
      // Clear content and emit it back to the parent
      this.$emit("input", "");
      this.content = "";
      this.quill.setContents([]); // Clear the Quill editor
    },
  },
};
</script>

<style>
.quill-editor {
  height: 400px; /* Adjust height as needed */
}
</style>
