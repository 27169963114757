<template>
  <svg :width="size" :height="size" :viewBox="`0 0 24 24`">
    <path
      d="M20.5619 7.14558L16.3722 2.9559C16.2504 2.834 16.1057 2.73731 15.9464 2.67135C15.7872 2.60538 15.6165 2.57143 15.4441 2.57143C15.2717 2.57143 15.1011 2.60538 14.9418 2.67135C14.7826 2.73731 14.6379 2.834 14.516 2.9559L2.95567 14.5171C2.83354 14.6388 2.73671 14.7835 2.67074 14.9428C2.60477 15.1021 2.57097 15.2729 2.57129 15.4453V19.635C2.57129 19.9831 2.70957 20.3169 2.95571 20.563C3.20186 20.8092 3.53569 20.9475 3.88379 20.9475H8.07348C8.24589 20.9478 8.41666 20.914 8.57595 20.848C8.73524 20.782 8.8799 20.6852 9.0016 20.5631L20.5619 9.00183C20.6838 8.87996 20.7805 8.73526 20.8465 8.57601C20.9124 8.41676 20.9464 8.24608 20.9464 8.07371C20.9464 7.90134 20.9124 7.73065 20.8465 7.57141C20.7805 7.41216 20.6838 7.26746 20.5619 7.14558ZM8.20567 19.7671C8.17066 19.8024 8.12313 19.8223 8.07348 19.8225H3.88379C3.83406 19.8225 3.78637 19.8027 3.75121 19.7675C3.71605 19.7324 3.69629 19.6847 3.69629 19.635V15.4453C3.6965 15.3956 3.71639 15.3481 3.7516 15.3131L12.1338 6.92996L16.5879 11.385L8.20567 19.7671ZM19.766 8.20683L17.3838 10.589L12.9297 6.13496L15.3119 3.75183C15.3293 3.7344 15.35 3.72057 15.3728 3.71114C15.3955 3.7017 15.4199 3.69684 15.4446 3.69684C15.4692 3.69684 15.4936 3.7017 15.5164 3.71114C15.5391 3.72057 15.5598 3.7344 15.5772 3.75183L19.766 7.94152C19.7834 7.95893 19.7972 7.97961 19.8067 8.00238C19.8161 8.02514 19.821 8.04954 19.821 8.07418C19.821 8.09882 19.8161 8.12322 19.8067 8.14598C19.7972 8.16874 19.7834 8.18942 19.766 8.20683Z"
      :fill="color"
    />
  </svg>
</template>

<script>
import IconMixin from "../../mixins/IconMixin"

export default {
  mixins: [IconMixin]
};
</script>