<template>
  <div>
    <v-card tile class="pa-2 pt-3" flat>
      <v-card-text style="gap: 10px" class="row align-center">
        <div class="col d-flex align-center pa-4" style="gap: 10px">
          <span class="span_header"
            >Total Hours :
            <b> {{ astimationHours || totalProjectTime / 60 }} </b>
          </span>
          <v-divider
            :thickness="5"
            class="border-opacity-90"
            :vertical="true"
          ></v-divider>
          <span class="span_header"
            >Spent Hours :
            <b style="color: green"> {{ totalProjectTime / 60 }} </b>
          </span>
          <v-divider
            :thickness="5"
            class="border-opacity-90"
            :vertical="true"
          ></v-divider>
          <span class="span_header"
            >Pending Hours : <b style="color: red"> {{ paddingHours }} </b>
          </span>
          <v-divider
            v-if="astimationHours"
            :thickness="5"
            class="border-opacity-90"
            :vertical="true"
          ></v-divider>
          <span class="span_header" v-if="astimationHours"
            >Extra Hours : <b class="primary--text"> {{ extraHours }} </b>
          </span>
          <v-spacer></v-spacer>
          <span class="span_header"
            >Start Date :<b> {{ formattedDate(project.start_date) }}</b></span
          >
          <span class="span_header"
            >Deadline : <b>{{ formattedDate(project.end_date) }}</b></span
          >
        </div>
      </v-card-text>
      <v-data-table
        :loading="loading"
        :headers="headersList"
        :items="projectTeamMembers"
        :page.sync="currentPage"
        :search="search"
        :items-per-page="15"
        class="custom-data-table mx-4"
      >
        <template v-slot:[`item.name`]="{ item }">
          <v-avatar size="36px" class="mr-3" v-if="item.employee?.profile_url">
            <v-img alt="Avatar" :src="item?.employee?.profile_url"></v-img>
          </v-avatar>
          <v-avatar
            v-else
            size="36px"
            class="mr-3 white--text"
            color="secondary"
          >
            {{ item.employee?.intials }}
          </v-avatar>
          {{ item.employee?.name }}
        </template>

        <template v-slot:[`item.total_hours`]="{ item }">
          <v-chip
            style="
              height: 28px;
              width: 90px; /* Set a fixed width */
              border-radius: 6px;
              text-align: center !important;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 700;
            "
            label
            class="mr-3 white--text"
            color="secondary"
          >
            {{ item.totalTime / 60 }}
          </v-chip>
        </template>
        <template v-slot:[`item.spent_hours`]="{ item }">
          <v-chip
            style="
              height: 28px;
              width: 90px; /* Set a fixed width */
              border-radius: 6px;
              text-align: center !important;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 700;
            "
            label
            class="mr-3 white--text"
            color="success"
          >
            {{ item.totalTime / 60 }}
          </v-chip>
        </template>
        <template v-slot:[`item.pending_hours`]="{}">
          <v-chip
            style="
              height: 28px;
              width: 90px; /* Set a fixed width */
              border-radius: 6px;
              text-align: center !important;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 700;
            "
            label
            class="mr-3 white--text"
            color="error"
          >
            0
          </v-chip>
        </template>
        <template v-slot:[`item.extra_hours`]="{}">
          <v-chip
            style="
              height: 28px;
              width: 90px; /* Set a fixed width */
              border-radius: 6px;
              text-align: center !important;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 700;
            "
            label
            class="mr-3 white--text"
            color="primary"
          >
            0
          </v-chip>
        </template>

        <!-- active -->
        <template
          v-slot:[`item.active`]="{ item }"
          v-if="user.role === 'admin'"
        >
          <v-switch
            v-model="item.employee.active"
            :value="item?.employee?.active"
            @change="disableEmployeeListing(item)"
            color="green"
            inset
          >
          </v-switch>
        </template>

        <!-- action links -->

        <template
          v-slot:[`item.actions`]="{ item }"
          v-if="user.role === 'admin'"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                @click="empDetails(item.employee_id)"
                class="cursor-pointer mr-3"
                v-bind="attrs"
                v-on="on"
              >
                <EyeIcon :size="20" />
              </span>
            </template>
            <span>View Detail</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                @click="editItem(item)"
                class="cursor-pointer mr-3"
                v-bind="attrs"
                v-on="on"
              >
                <PencilIcon :size="19" />
              </span>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                @click.prevent="setDelete(item.id)"
                class="cursor-pointer"
                v-bind="attrs"
                v-on="on"
              >
                <TrashIcon :size="19" />
              </span>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <!-- confirmation dialog for deleting employee -->
      <v-dialog v-model="dialogDelete" max-width="450px">
        <v-card>
          <v-card-title class="headline">Alert</v-card-title>
          <v-card-title class="subtitle-1 mb-4"
            >Are you sure you want to Remove this Employee ?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDelete"
              :disabled="isDeletingMember"
              >Cancel</v-btn
            >
            <v-btn
              color="red darken-1"
              text
              @click="removemember"
              :loading="isDeletingMember"
              >Delete</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>

    <Dialog
      :isOpen="showEmployeeAddEditDialog"
      :save="updateEmp"
      @close="showEmployeeAddEditDialog = false"
      title="Edit Employee"
      :action="true"
    >
      <template>
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-text-field
              label="Employee Name "
              name="name"
              v-model="emp.name"
              :error="errors.name != null"
              :error-messages="errors.name"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-text-field
              label="Email"
              name="email"
              v-model="emp.email"
              :error="errors.email != null"
              :error-messages="errors.email"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-select
              :items="roles"
              v-model="emp.role_id"
              :error="errors.role_id != null"
              :error-messages="errors.role_id"
              item-text="name"
              item-value="role_id"
              label="Role"
              name="role_id"
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" class="py-0 mx-0">
            <v-checkbox
              class="mt-0"
              v-model="invited"
              label="Send Invitation"
            ></v-checkbox>
          </v-col>
        </v-row>
      </template>
    </Dialog>

    <!-- for success message -->
    <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      color="green accent-4"
      align-baseline="center"
    >
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>

    <!-- for error message -->
    <v-snackbar
      v-model="snackbarError"
      :timeout="3000"
      color="deep-orange accent-4"
      align-baseline="center"
    >
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import PencilIcon from "../../assets/icons/PencilIcon.vue";
import TrashIcon from "../../assets/icons/TrashIcon.vue";
import Dialog from "../common/basic/Dialog.vue";
import EyeIcon from "../../assets/icons/EyeIcon.vue";

export default {
  name: "ProjectTeam",
  props: [
    "project_id",
    "selected",
    "value",
    "project",
    "refreshTeamMembersList",
    "members",
  ],
  data: () => ({
    loading: false,
    snackbar: false,
    roles: [],
    search: "",
    snackbarError: false,
    employee_id: [],
    employee_role: "Member",
    message: "",
    errors: [],
    currentPage: 1,
    dialogDelete: false,
    deleteingId: "",
    searchInput: null,
    isDataUpdated: false,
    showAddMemberDialog: false,
    isSavingMembers: false,
    isLoadingMembers: false,
    isDeletingMember: false,
    isAdmin: false,
    totalProjectTime: 0,
    addNewEmployeeDialog: false,
    showEmployeeAddEditDialog: false,
    user: {},
    emp: {},
    item: {
      name: "",
      email: "",
      password: "",
      role_id: "",
    },
    invited: true,
    headers: [
      {
        text: " Team member name",
        align: "start",
        value: "name",
        sortable: false,
      },
      {
        text: "Role",
        value: "employee_role",
        sortable: false,
      },
      {
        text: "Total Hours",
        sortable: false,
        value: "total_hours",
      },
      {
        text: "Spent Hours",
        sortable: false,
        value: "spent_hours",
      },
      {
        text: "Pending Hours",
        sortable: false,
        value: "pending_hours",
      },
      {
        text: "Extra  Hours",
        sortable: false,
        value: "extra_hours",
      },
    ],
  }),
  components: {
    TrashIcon,
    PencilIcon,
    Dialog,
    EyeIcon,
  },
  computed: {
    ...mapState("tasks", ["syncProject"]),
    ...mapState("projects", ["projectTeamMembers", "cachesTeamMembers"]),
    showNoMembers() {
      return this.members?.length == 0 && !this.isLoadingMembers;
    },
    astimationHours() {
      return parseInt(this.project.total_hours || 0);
    },
    paddingHours() {
      return this.astimationHours
        ? this.astimationHours - this.totalProjectTime / 60 > 0
          ? this.astimationHours - this.totalProjectTime / 60
          : 0
        : 0;
    },
    headersList() {
      let data = this.headers;
      if (this.user.role === "admin") {
        data.push(
          {
            text: "Active / Inactive",
            value: "active",
          },
          { text: "Actions", value: "actions", sortable: false }
        );
      }
      return data;
    },
    extraHours() {
      return this.totalProjectTime / 60 - this.astimationHours > 0
        ? this.totalProjectTime / 60 - this.astimationHours
        : 0;
    },
  },
  methods: {
    ...mapMutations("projects", [
      "setProjectTeamMembers",
      "updateProjectCaches",
    ]),
    ...mapMutations("tasks", ["setSyncProject"]),
    formattedDate(input) {
      const date = new Date(input);
      if (isNaN(date.getTime())) {
        return "--/--/----";
      }
      const options = { month: "long", year: "numeric" };
      const formattedMonth = date.toLocaleDateString("en-US", options);
      const day = date.getDate();
      return `${day}${this.getDaySuffix(day)} ${formattedMonth}`;
    },
    getDaySuffix(day) {
      if (day >= 11 && day <= 13) {
        return "th";
      }
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    },

    empDetails(employee_id) {
      this.$router.push({ path: "/employees/employee-details/" + employee_id });
    },
    removemember() {
      let _self = this;
      this.isDeletingMember = true;

      _self.$axios
        .delete("/removemember/" + _self.deleteingId)
        .then((res) => {
          let resdata = res.data;

          if (resdata.success) {
            _self.snackbar = true;
            _self.message = resdata.message;

            this.updateProjectCaches({
              key: "cachesTeamMembers",
              value: false,
            });

            this.$emit("refreshTeamMembersList");
            _self.closeDelete();
          } else {
            _self.snackbarError = true;
            _self.message = resdata.message;
          }
        })
        .finally(() => {
          this.isDeletingMember = false;
        });
    },
    setDelete(id) {
      this.deleteingId = id;
      this.dialogDelete = true;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.deleteingId = "";
    },
    getEmployeeDetail(id) {
      this.$axios.get("employee/" + id).then((res) => {
        if (res.data.success) {
          this.emp = res.data.employee;
        } else {
          this.snackbarError = true;
          this.message = res.data.message;
        }
      });
    },
    editItem(item) {
      this.form_type_insert = false;
      this.editedIndex = this.projectTeamMembers.indexOf(item);
      this.getEmployeeDetail(item.employee_id);
      this.showEmployeeAddEditDialog = true;
      this.errors = [];
    },
    disableEmployeeListing(item) {
      let _self = this;
      this.$axios
        .post("/disableEmployeeListing", {
          employee_id: item.employee_id,
        })
        .then((res) => {
          let data = res.data;

          if (!data.success) {
            _self.snackbarError = true;
            _self.message = data.message;
          } else {
            _self.snackbar = true;
            _self.message = data.message;
          }
        });
    },
    updateEmp() {
      this.loading = true;
      this.$axios.put("/employee/" + this.emp.id, this.emp).then((res) => {
        let _self = this;
        let data = res.data;

        if (!data.success) {
          _self.errors = [];
          if (data.errors) {
            for (let i = 0; i < data.errors?.length; i++) {
              _self.errors[data.errors[i].field] = data.errors[i].message;
            }
          }

          _self.showEmployeeAddEditDialog = false;
        } else {
          _self.snackbar = true;
          _self.message = data.message;
          _self.close();
          this.updateProjectCaches({ key: "cachesTeamMembers", value: false });

          this.$emit("refreshTeamMembersList");
        }
        _self.loading = false;
      });
    },

    closeAddMemberDialog() {
      this.showAddMemberDialog = false;
      this.errors = [];
      this.employee_id = [];
    },
    removeSelectedMember(employeeId) {
      if (Array.isArray(this.employee_id)) {
        this.employee_id = this.employee_id.filter((id) => id != employeeId);
      }
    },
    closeTeamMembersDialog() {
      this.closeAddMemberDialog();
      this.$emit("close");
    },
    getRoles() {
      let _self = this;

      this.$axios.post("roles").then((res) => {
        let resdata = res.data;

        if (resdata.success) {
          _self.roles = resdata.roles;
          this.item.role_id = this.roles[0].role_id;
        } else {
          _self.snackbarError = true;
          _self.message = resdata.message;
        }
      });
    },
    openAddEmployeeDialog() {
      // this.showAddMemberDialog = false;
      this.addNewEmployeeDialog = true;
      this.errors = [];
      this.item.role_id = this.roles[0].role_id;
    },
    closeAddEmployeeDialog() {
      this.showAddMemberDialog = false;
      this.addNewEmployeeDialog = false;
      this.item = {};
    },

    close() {
      this.item.name = "";
      this.item.email = "";
      this.item.password = "";
      this.item.role_id = "";
      this.showAddMemberDialog = false;
      this.addNewEmployeeDialog = false;
      this.showEmployeeAddEditDialog = false;
    },
  },
  created() {
    let user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      this.isAdmin = user.role == "admin";
    }
  },
  mounted() {
    let localUser = localStorage.getItem("user");
    this.user = JSON.parse(localUser);
    if (this.user.role === "admin") {
      this.getRoles();
    }
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer !important;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

.member-item:not(:last-child) {
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 3px;
}

.member-item .delete-btn {
  display: none;
}

.member-item:hover .delete-btn {
  display: block;
}
</style>

<style>
.team-member-select {
  color: var(--primary-font-color) !important;
}

.team-member-select fieldset {
  border: 3px solid var(--border-color) !important;
  border-radius: 6px 6px 0px 0px !important;
}

.team-member-select .v-label {
  top: 16px !important;
}

.team-member-select .v-select__slot {
  padding: 6px 0px !important;
}

.selected-team-member-container {
  border-top: none;
  border: 2px solid var(--border-color) !important;
  background: #f0f4f8;
  border-radius: 0px 0px 6px 6px !important;
}

.add-team-float-btn {
  position: fixed;
  bottom: 10px;
  right: 20px;
}
.span_header {
  font-size: 14px;
  color: #323338;
  font-weight: 400;
}
</style>
