var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{staticClass:"shadow-1",scopedSlots:_vm._u([{key:"activator",fn:function({ on: menu, attrs }){return [_c('v-chip',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":_vm.priorityDetails.color,"dark":"","loading":_vm.loading,"small":""}},'v-chip',attrs,false),{ ...menu }),[_c('v-icon',{style:({
          color: `${
            _vm.isDark(_vm.priorityDetails.color) ? 'white' : 'black'
          } !important`,
        }),attrs:{"size":"20px"}},[_vm._v("mdi mdi-flag-variant-outline")]),_c('span',{style:({
          color: `${
            _vm.isDark(_vm.priorityDetails.color) ? 'white' : 'black'
          } !important`,
          fontWeight: 600,
        })},[_vm._v(" "+_vm._s(_vm.priorityDetails.name)+" ")])],1)]}}])},[_c('v-list',{attrs:{"dense":"","flat":""}},[_vm._l((_vm.priorities),function(priority,index){return _c('v-list-item',{key:index,staticClass:"px-2 cursor-pointer",on:{"click":function($event){return _vm.$emit('setPriority', priority.id)}}},[_c('v-list-item-title',{staticClass:"py-2 px-5 text-center",style:({
          color: `${_vm.isDark(priority.color) ? 'white' : 'black'} !important`,
          backgroundColor: `${priority.color}`,
        })},[_vm._v(" "+_vm._s(priority.name)+" ")])],1)}),_c('v-list-item',{staticClass:"px-2 cursor-pointer",on:{"click":function($event){return _vm.$emit('setPriority', null)}}},[_c('v-list-item-title',{staticClass:"py-2 px-5 text-center",style:({
          backgroundColor: '#f5f5f5',
        })},[_vm._v(" Clear ")])],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }