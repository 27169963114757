<template>
  <div class="loading-dots">
    <div
      class="loading-dots--dot"
      v-for="dotIndex in 3"
      :key="dotIndex"
      :style="{ backgroundColor: bgColor }"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    bgColor: {
      type: String,
      default: "black",
    },
  },
};
</script>

<style scoped lang="scss">
@keyframes dot-keyframes {
  0% {
    opacity: 0.3;
    transform: scale(1, 1);
  }

  50% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }

  100% {
    opacity: 0.3;
    transform: scale(1, 1);
  }
}

.loading-dots {
  text-align: center;
  width: 100%;

  &--dot {
    animation: dot-keyframes 1.5s infinite ease-in-out;
    border-radius: 8px;
    display: inline-block;
    height: 8px;
    width: 8px;

    &:nth-child(2) {
      animation-delay: 0.5s;
    }

    &:nth-child(3) {
      animation-delay: 1s;
    }

    &:not(:last-of-type) {
      margin-right: 5px;
    }
  }
}
</style>