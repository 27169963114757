<template>
  <div>
    <Dialog
      :isOpen="value"
      @close="closeAddEditTask"
      :loading="isSavingTaskDetails"
      :save="saveOrUpdateTask"
      :action="true"
      :title="isEditingTask ? `Edit Task` : `Add Task`"
    >
      <template>
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-text-field
              v-model="editedItem.name"
              label="Title"
              outlined
              dense
              autofocus
              :error="errors.name != null"
              :error-messages="errors.name"
            />
          </v-col>
          <v-col cols="12" class="mt-0 pt-0">
            <span class="caption grey--text text--darken-2">Description</span>
            <Editor v-model="editedItem.description" />
            <span :v-if="errors.description != null">{{
              errors.description
            }}</span>
          </v-col>
          <v-col cols="12" class="pb-0">
            <v-select
              v-model="editedItem.list_id"
              label="List"
              :items="projectLists"
              item-text="name"
              item-value="list_id"
              outlined
              dense
              :error="errors.list_id != null"
              :error-messages="errors.list_id"
            >
            </v-select>
          </v-col>
          <v-col cols="12" class="pb-0 d-flex">
            <v-autocomplete
              v-model="tagList"
              :items="projectTags"
              item-text="name"
              item-value="id"
              label="Tag"
              outlined
              dense
              multiple
              chips
              deletable-chips
              @input="searchInput = null"
              :search-input.sync="searchInput"
              small-chips
            >
              <template v-slot:item="data">
                <template>
                  <v-list-item-icon style="margin: 8px 16px 8px 0">
                    <v-icon :color="data.item.color">mdi-tag-multiple</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="data.item.name"
                    ></v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
            <!-- <v-btn icon small>
              <v-icon>mdi-tag-multiple-outline</v-icon>
            </v-btn> -->

            <v-menu offset-y min-width="250px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon small v-bind="attrs" v-on="on" class="tag">
                  <v-icon>mdi-tag-multiple-outline</v-icon>
                </v-btn>
              </template>
              <div class="white">
                <v-text-field
                  v-model="newTag.name"
                  @click.native.stop
                  dense
                  hide-details
                  solo
                  flat
                  placeholder="Add New Tag"
                  class="subtitle-2 py-1"
                >
                  <template v-slot:prepend-inner>
                    <v-menu
                      top
                      nudge-bottom="300"
                      nudge-left="16"
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on }">
                        <div :style="swatchStyle" v-on="on"></div>
                      </template>
                      <v-card>
                        <v-card-text class="pa-0">
                          <v-color-picker v-model="newTag.color" flat />
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </template>
                  <template v-slot:append>
                    <v-btn
                      @click="storeNewTag()"
                      color="primary"
                      x-small
                      :style="{ marginTop: '4px', backgroundColor: 'white' }"
                    >
                      save
                    </v-btn>
                  </template>
                </v-text-field>
              </div>
            </v-menu>
          </v-col>
          <v-col cols="12" class="pb-0">
            <v-select
              v-model="editedItem.status_id"
              label="Status"
              :items="selectedListStatuses"
              item-text="name"
              item-value="id"
              outlined
              dense
              :loading="isLoadingListStatuses"
              :error="errors.status_id != null"
              :error-messages="errors.status_id"
            >
            </v-select>
          </v-col>
          <v-col cols="12" class="pb-0">
            <v-select
              v-model="editedItem.task_priority"
              label="Task Priority"
              :items="priorities"
              item-text="name"
              item-value="id"
              outlined
              dense
              :error="errors.id != null"
              :error-messages="errors.id"
            >
            </v-select>
          </v-col>
          <v-col cols="12" class="pb-0">
            <v-autocomplete
              v-model="editedItem.assignees"
              :items="projectTeamMembers"
              item-text="employee.name"
              item-value="employee.id"
              label="Assignee"
              outlined
              dense
              multiple
              chips
              deletable-chips
              @input="searchInput = null"
              :search-input.sync="searchInput"
              :error="errors.assignees != null"
              :error-messages="errors.assignees"
            >
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-avatar
                    style="min-width: 33px; height: 33px; width: 33px"
                  >
                    <Avatar
                      size="30px"
                      :color="data.item.employee?.color"
                      :intials="data.item.employee?.intials"
                      :profile_url="data.item.employee?.profile_url"
                    />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="data.item.employee.name"
                    ></v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" class="mb-2">
            <!-- <v-file-input v-model="editedItem.attachments" accept="image/*,.pdf" label="Attachments" multiple chips dense
              :error="errors.attachments != null" :error-messages="errors.attachments"></v-file-input> -->
            <v-btn
              color="#858585"
              small
              text
              class="ml-0 px-0"
              @click="setAttachmentDialog"
            >
              <v-icon>mdi-paperclip</v-icon>
              Attachment
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </Dialog>
    <Upload
      ref="attachmentfile-uploader"
      :dialog.sync="AttachmentDialog"
      :multiple="true"
      @filesUploaded="storeAttachment($event)"
    />

    <v-snackbar
      v-model="alert.show"
      :timeout="3000"
      :color="alert.color"
      align-baseline="center"
    >
      {{ alert.message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="alert.show = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Editor from "../editor/Editor.vue";
import { mapState, mapMutations } from "vuex";
import { cloneDeep } from "lodash";
import Avatar from "../../common/basic/Avatar.vue";
import Upload from "../../shared/UploadFile.vue";
import Dialog from "../basic/Dialog.vue";

const defaultEditedItemValue = {
  name: "",
  list_id: "",
  status_id: "",
  description: "",
  attachments: [],
  assignees: [],
  task_id: "",
  task_priority: "",
};

export default {
  props: {
    value: {
      type: Boolean,
    },
    isEditingTask: {
      type: Boolean,
      default: false,
    },
    taskDetails: {
      type: Object,
      default: cloneDeep(defaultEditedItemValue),
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Dialog,
    Editor,
    Upload,
    Avatar,
  },
  watch: {
    taskDetails: function () {
      this.editedItem =
        cloneDeep(this.taskDetails) || cloneDeep(defaultEditedItemValue);
    },
    value: function (isOpen) {
      if (!isOpen) {
        this.errors = {};
        this.editedItem = cloneDeep(defaultEditedItemValue);
        this.isSavingTaskDetails = false;
      }
    },

    "editedItem.list_id": function (listId) {
      if (listId) {
        this.getListStatuses();
        this.project_id = this.$route.params.id;
        this.getProjectTags();
      }

      // reset status
      if (this.taskDetails.list_id != this.editedItem.list_id) {
        this.editedItem = {
          ...this.editedItem,
          status_id: null,
        };
      }
    },
  },
  computed: {
    ...mapState("tasks", ["priorities"]),
    ...mapState("projects", [
      "projectLists",
      "selectedListId",
      "projectTeamMembers",
      "selectedListStatuses",
      "selectedListId",
      "projectTags",
      "cachesTags",
      "cachesStatusesList",
    ]),
    swatchStyle() {
      const { menu } = this;
      return {
        backgroundColor: this.newTag.color,
        cursor: "pointer",
        height: "15px",
        width: "15px",
        borderRadius: menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
        marginTop: "7px",
        marginRight: "3px",
      };
    },
  },
  data() {
    return {
      editedItem: cloneDeep(defaultEditedItemValue),
      errors: {},
      isSavingTaskDetails: false,
      searchInput: null,
      alert: {
        show: false,
        message: "",
        color: "",
      },
      isLoadingListStatuses: false,
      attachments: [],
      AttachmentDialog: false,
      tagList: "",

      project_id: "",
      newTag: {
        name: null,
        color: "#006EFF",
        project_id: null,
      },
      newAddTaskId: "",
    };
  },
  methods: {
    ...mapMutations("projects", [
      "setSelectedListStatuses",
      "setProjectTags",
      "updateProjectCaches",
    ]),

    showAlertMessage(isSucessMsg, message) {
      this.alert = {
        message: message || "",
        color: isSucessMsg ? "green accent-4" : "red accent-2",
        show: true,
      };
    },
    setAttachmentDialog() {
      this.attachments = [];
      this.AttachmentDialog = true;
    },
    storeAttachment(files) {
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        this.attachments.push(file);
      }

      if (this.attachments.length > 0) {
        this.$refs["attachmentfile-uploader"].closeDialog();
      }
    },
    getListStatuses() {
      if (!this.editedItem || !this.editedItem.list_id) {
        console.log("List id not found");
      }
      if (this.cachesStatusesList) {
        return;
      }

      this.isLoadingListStatuses = true;

      this.$axios
        .get(`/list/${this.editedItem.list_id}/statuses`)
        .then((response) => {
          const resData = response.data;

          if (resData.success) {
            this.setSelectedListStatuses(resData.data);
            this.updateProjectCaches({
              key: "cachesStatusesList",
              value: true,
            });
          } else {
            this.showAlertMessage(
              false,
              resData.message || "Something went wrong"
            );
          }
        })
        .catch(() => {
          this.showAlertMessage(false, "Something went wrong");
        })
        .finally(() => {
          this.isLoadingListStatuses = false;
        });
    },
    getProjectTags() {
      if (this.cachesTags) {
        return;
      }
      let _self = this;
      _self.$axios
        .get("get_project_tags/" + this.project_id)
        .then((response) => {
          let data = response.data;
          if (data.success) {
            this.setProjectTags(data.tags);
            this.updateProjectCaches({ key: "cachesTags", value: true });
          } else {
            this.error = true;
            this.message = data.message;
          }
        });
    },
    storeNewTag() {
      let objdata = {
        name: this.newTag.name,
        color: this.newTag.color,
        project_id: this.project_id,
      };
      let _self = this;
      _self.$axios.post("add_new_tags", objdata).then((response) => {
        let data = response.data;
        if (data.success) {
          this.setProjectTags([...this.projectTags, data.tags]);

          this.loading = false;
          this.newTag.name = "";
          this.newTag.color = "#006EFF";
        } else {
          this.error = true;
          this.message = data.message ? data.message : data.errors[0].message;
          this.loading = false;
        }
      });
    },
    saveOrUpdateTask() {
      this.isSavingTaskDetails = true;
      const {
        task_id,
        name,
        description,
        list_id,
        status_id,
        task_priority,
        assignees,
      } = this.editedItem;

      let formData = new FormData();

      formData.append("name", name);
      formData.append("description", description);
      formData.append("list_id", list_id);
      formData.append("task_priority", task_priority);

      if (status_id) {
        formData.append("status_id", status_id);
      }

      if (assignees.length > 0) {
        for (let j = 0; j < assignees.length; j++) {
          formData.append("assignees[]", assignees[j]);
        }
      }

      if (this.attachments) {
        for (let i = 0; i < this.attachments.length; i++) {
          formData.append(`attachments[${i}]`, this.attachments[i]);
        }
      }

      if (this.isEditingTask) {
        this.$axios
          .put("tasks/" + task_id, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            const resData = response.data;

            if (resData.success) {
              this.showAlertMessage(true, resData.message);
              this.$emit("taskSaved", resData.task);
            } else {
              if (resData.errors) {
                let errors = {};

                for (let i = 0; i < resData.errors.length; i++) {
                  errors[resData.errors[i].field] = resData.errors[i].message;
                }

                this.errors = errors;
              } else {
                this.showAlertMessage(false, resData.message);
              }
            }
          })
          .catch(() => {
            this.showAlertMessage(false, "Something went wrong");
          })
          .finally(() => {
            this.isSavingTaskDetails = false;
          });
      } else {
        this.$axios
          .post("tasks", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            const resData = response.data;

            if (resData.success) {
              this.showAlertMessage(true, resData.message);
              this.newAddTaskId = resData.task;
              this.$emit("taskSaved", resData.task);
              this.addTages();
            } else {
              if (resData.errors) {
                let errors = {};

                for (let i = 0; i < resData.errors.length; i++) {
                  errors[resData.errors[i].field] = resData.errors[i].message;
                }

                this.errors = errors;
              } else {
                this.showAlertMessage(false, resData.message);
              }
            }
          })
          .catch(() => {
            this.showAlertMessage(false, "Something went wrong");
          })
          .finally(() => {
            this.isSavingTaskDetails = false;
          });
      }
    },
    closeAddEditTask() {
      this.attachments = [];
      this.$emit("close");
      this.tagList = "";
      this.newAddTaskId = "";
    },
    addTages() {
      if (this.tagList.length > 0) {
        for (let item of this.tagList) {
          let _self = this;
          _self.$axios
            .post("add_task_tags/" + item, {
              task_id: this.newAddTaskId.task_id,
            })
            .then(() => {
              // let data = response.data;
            })
            .catch(() => {
              this.showAlertMessage(false, "Something went wrong in tag");
            });
        }
      }
    },
  },
};
</script>
<style>
.v-select.v-input--dense .v-chip {
  margin: 4px !important;
}

.tag {
  align-items: center;
  justify-content: center;
  width: 40px !important;
  height: 40px !important;
  border-radius: 6px;
  background-color: #f0f4f8;
  margin-left: 5px;
}
</style>
