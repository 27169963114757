<template>
  <v-row class="fill-height mt-2 pa-5 pt-1" :class="{ noselect: noselect }">
    <v-col>
      <v-sheet height="64">
        <v-toolbar flat class="custom-toolbar">
          <v-btn fab text small color="secondary" class="mr-6" @click="prev">
            <v-icon> mdi-chevron-left </v-icon>
          </v-btn>
          <v-toolbar-title
            v-if="$refs.calendar"
            class="subtitle-1 font-weight-bold"
          >
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-btn fab text small color="secondary" class="ml-6" @click="next">
            <v-icon> mdi-chevron-right </v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <div class="header-actions-container">
            <v-btn
              outlined
              color="secondary"
              class="border-1 border-radius-6"
              @click="setToday"
            >
              Today
            </v-btn>
            <v-btn-toggle
              v-model="type"
              mandatory
              dense
              borderless
              class="border-1 border-radius-6"
              color="secondary"
              active-class="active-btn"
            >
              <v-btn
                v-for="actionBtn in calendarActionButtons"
                :key="actionBtn.value"
                text
                :class="{ 'secondary--text': type != actionBtn.value }"
                :value="actionBtn.value"
                width="80"
              >
                {{ actionBtn.label }}
              </v-btn>
            </v-btn-toggle>
            <v-btn
              color="primary"
              dark
              depressed
              class="px-3 border-radius-6 custom-data-table-action-btn"
              @click.stop="openAddPlanningDialog"
            >
              <v-icon class="mr-1"> mdi-plus </v-icon>
              Add
            </v-btn>
          </div>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="events"
          :event-overlap-mode="mode"
          :event-color="getEventColor"
          :type="type"
          @click:more="viewMore"
          @click:date="viewDay"
          @change="updateRange"
          @click:event="showEvent"
          :first-interval="10"
          :interval-minutes="60"
          :interval-count="11"
          :interval-height="50"
          :event-ripple="false"
          class="custom-calendar"
        >
          <template v-slot:interval="{ hour }">
            <div
              class="text-center block fill-height"
              @click="setHour(hour)"
            ></div>
          </template>
        </v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
          max-width="600px"
        >
          <v-card color="grey lighten-4" min-width="350px" flat width="600px">
            <v-toolbar :color="selectedEvent.color" dark>
              <v-btn
                icon
                @click="
                  is_edit = true;
                  showEditDialog();
                "
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn class="mr-2" icon @click.prevent="dialogDelete = true">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-expansion-panels
                multiple
                focusable
                v-if="selectedEvent.tasks && selectedEvent.tasks?.length > 0"
                class="mb-3"
              >
                <v-expansion-panel
                  v-for="task in selectedEvent.tasks"
                  :key="task.task.task_id"
                >
                  <v-expansion-panel-header>{{
                    task.task.name
                  }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <span v-html="task.task.description"></span>
                    <br /><br />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-row>
                <v-col cols="12" v-show="selectedEvent.tasks?.length == 0">
                  No Tasks Available
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn text color="secondary" @click="selectedOpen = false">
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>

    <Dialog
      :isOpen="showAddEditPlanningDialog"
      @close="closeDialog"
      :action="!selectTaskDialog"
      :title="!is_edit ? `Add Planning` : `Edit Planning`"
      :save="saveUpdate"
    >
      <template>
        <template>
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-select
                :items="projectTeamMembers"
                label="Employee Name"
                dense
                item-text="employee.name"
                item-value="employee.id"
                outlined
                v-model="employee.employee_id"
                :error="errors.employee_id != null"
                :error-messages="errors.employee_id"
              ></v-select>
            </v-col>
            <v-col cols="12" class="dataTimePiker py-0">
              <v-datetime-picker
                label="Start Date And Time"
                v-model="employee.start_datetime"
                v-show="employee.employee_id"
              >
                <template slot="dateIcon">
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>mdi-clock</v-icon>
                </template>
              </v-datetime-picker>
              <span
                v-if="errors.start_datetime != null"
                class="red--text caption"
              >
                {{ errors.start_datetime }}
              </span>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="employee.end_datetime"
                transition="scale-transition"
                offset-y
                min-width="auto"
                v-show="employee.employee_id"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    dense
                    v-model="employee.end_datetime"
                    label="End Date"
                    v-bind="attrs"
                    v-on="on"
                    append-icon="mdi mdi-calendar-blank-outline"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="employee.end_datetime"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu2 = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu2.save(employee.end_datetime)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <span
                v-if="errors.end_datetime != null"
                class="red--text caption"
              >
                {{ errors.end_datetime }}
              </span>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                label="Hours"
                placeholder="Hours"
                outlined
                dense
                v-model="employee.hours"
                name="hours"
                class="pa-0"
                :error="errors.hours != null"
                :error-messages="errors.hours"
              ></v-text-field>
            </v-col>

            <v-col cols="12" class="py-0">
              <v-autocomplete
                outlined
                :items="filteredTasks"
                item-text="name"
                item-value="task_id"
                chips
                clearable
                dense
                deletable-chips
                multiple
                small-chips
                label="Select task"
                v-model="employee.task_id"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </template>
      </template>
    </Dialog>

    <!-- confirmation dialog for deleting planning-->
    <v-dialog v-model="dialogDelete" max-width="450px">
      <v-card>
        <v-card-title class="headline">Alert</v-card-title>
        <v-card-title class="subtitle-1 mb-4"
          >Are you sure you want to Remove this Planning?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false"
            >Cancel</v-btn
          >
          <v-btn color="red darken-1" text @click="deletePlanning()"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- for success message -->
    <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      color="green accent-4"
      align-baseline="center"
    >
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>

    <!-- for error message -->
    <v-snackbar
      v-model="snackbarError"
      :timeout="3000"
      color="deep-orange accent-4"
      align-baseline="center"
    >
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import moment from "moment";
import debounce from "v-debounce";
import Dialog from "../common/basic/Dialog.vue";

export default {
  props: ["project_id"],
  components: {
    Dialog,
  },
  data: () => ({
    is_edit: false,
    focus: "",
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
    },
    employee: {
      employee_id: "",
      start_datetime: new Date(),
      end_datetime: moment(new Date()).format("YYYY-MM-DD"),
      hours: "",
      task_id: [],
    },
    loading: false,
    snackbar: false,
    snackbarError: false,
    showAddEditPlanningDialog: false,
    members: [],
    message: "",
    type: "month",
    types: ["month", "week", "day"],
    weekday: [1, 2, 3, 4, 5, 6],
    mode: "column",
    noselect: false,
    value: "",
    events: [],
    selectedEvent: {
      tasks: [],
    },
    selectedElement: null,
    selectedOpen: false,
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    dragEvent: null,
    dragStart: null,
    createEvent: null,
    createStart: null,
    extendOriginal: null,
    names: [],
    categories: [],
    start_datetimeBeforeDrag: "",
    end_datetimeBeforeDrag: "",
    min: Number,
    max: Number,
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    menu2: false,
    errors: {},
    tasks: [],
    selectedDay: "",
    dialogDelete: false,
    searchResults: [],
    loadingTasks: false,
    selectTaskDialog: false,
    search: "",
    headers: [
      {
        text: "",
        value: "name",
        sortable: false,
      },
    ],
    calendarActionButtons: [
      {
        label: "Day",
        value: "day",
      },
      {
        label: "Week",
        value: "week",
      },
      {
        label: "Month",
        value: "month",
      },
    ],
  }),
  computed: {
    ...mapState("tasks", ["syncProject"]),
    ...mapState("projects", ["projectTeamMembers", "cachesTeamMembers"]),
    filteredTasks: function () {
      // filter tasks based on the search
      if (this.search && Array.isArray(this.searchResults)) {
        const regExp = new RegExp(this.search.toLowerCase(), "ig");

        return this.searchResults.filter(
          (task) => task.name.toLowerCase().search(regExp) != -1
        );
      }

      return this.searchResults;
    },
    selectedTasks: function () {
      let taskIds = this.employee.task_id;

      if (Array.isArray(taskIds)) {
        return this.searchResults
          .filter((task) => taskIds.includes(task.task_id))
          .map((task) => {
            return {
              task_id: task.task_id,
              name: task.name,
            };
          });
      }

      return [];
    },
  },
  watch: {
    syncProject() {
      this.syncClickUpTasksToLocal();
    },
    employee: function () {
      // this.employee.start_datetime = moment(this.employee.start_datetime).format('YYYY-MM-DD')
      this.employee.end_datetime = moment(this.employee.end_datetime).format(
        "YYYY-MM-DD"
      );
    },
    type: function () {
      if (this.type != "day") {
        this.employee.start_datetime = new Date();
      }
    },
    "employee.employee_id": function () {
      // this.employee.task_id = [];
      this.getAssignedTasks();
    },
    $route: function (route) {
      const query = route.query;

      if (query) {
        if (query.list_id) {
          this.getEmployeePlanning();
        }
      }
    },
  },
  methods: {
    ...mapMutations("tasks", ["setSyncProject"]),
    ...mapMutations("projects", [
      "setProjectTeamMembers",
      "updateProjectCaches",
    ]),
    convertDateFormat(date) {
      return moment(date).format("DD-MM-YYYY hh:mm A");
    },
    startDrag({ event, timed }) {
      this.start_datetimeBeforeDrag = event.start;
      this.end_datetimeBeforeDrag = event.end;
      this.noselect = true;
      if (event && timed) {
        this.dragEvent = event;
        this.dragTime = null;
        this.extendOriginal = null;
      }
    },
    startTime(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;

        this.dragTime = mouse - start;
      }
    },
    extendBottom(event) {
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        let newStart = this.roundTime(newStartTime);

        let newhour = moment(newStart).get("hour");

        if (newhour < 10) {
          newStart = moment(newStart).format("DD/MM/YYYY");
          newStart = moment(newStart + " 10 AM", "DD/MM/YYYY HH A").valueOf();
        }

        let newEnd = newStart + duration;
        newhour = moment(newEnd).get("hour");

        if (newhour >= 19) {
          newEnd = moment(newEnd).format("DD/MM/YYYY");
          newEnd = moment(newEnd + " 07 PM", "DD/MM/YYYY HH A").valueOf();
        }

        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        let min = Math.min(mouseRounded, this.createStart);
        let max = Math.max(mouseRounded, this.createStart);

        let newhour = moment(min).get("hour");

        if (newhour < 10) {
          min = moment(min).format("DD/MM/YYYY");
          min = moment(min + "10 AM", "DD/MM/YYYY HH A").valueOf();
        }

        newhour = moment(max).get("hour");

        if (newhour > 19) {
          max = moment(max).format("DD/MM/YYYY");
          max = moment(max + "07 PM", "DD/MM/YYYY HH A").valueOf();
        }

        this.createEvent.start = min;
        this.createEvent.end = max;
      }
    },
    endDrag() {
      // saving timing updates to the database
      let _self = this;
      let event = _self.createEvent;
      this.noselect = false;
      if (!event) {
        event = _self.dragEvent;
        _self.createEvent = _self.dragEvent;
      }

      _self.loading = true;
      _self.$axios
        .post("updateplanning", {
          id: event.id,
          employee_id: event.employee_id,
          start_datetime: event.start,
          end_datetime: event.end,
          hours: event.hours,
        })
        .then((res) => {
          let data = res.data;
          _self.loading = false;
          if (data.success) {
            this.dragTime = null;
            this.dragEvent = null;
            this.createEvent = null;
            this.createStart = null;
            this.extendOriginal = null;
          } else {
            let index = this.events.indexOf(this.createEvent);
            this.events[index].start = new Date(_self.start_datetimeBeforeDrag);
            this.events[index].end = new Date(_self.end_datetimeBeforeDrag);
            _self.start_datetimeBeforeDrag = null;
            _self.end_datetimeBeforeDrag = null;
            _self.message = data.message;
            _self.snackbarError = true;
          }
          this.cancelDrag();
        });
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        } else {
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            // this.events.splice(i, 1)
          }
        }
      }

      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
    },
    roundTime(time, down = true) {
      const roundTo = 15; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    saveUpdate() {
      if (this.is_edit) this.update();
      else this.save();
    },
    deletePlanning() {
      let _self = this;

      _self.$axios.delete("/planning/" + _self.selectedEvent.id).then((res) => {
        let data = res.data;

        if (data.success) {
          _self.selectedOpen = false;
          _self.dialogDelete = false;
          _self.getEmployeePlanning();
        } else {
          _self.snackbarError = true;
          _self.message = data.message;
        }
      });
    },
    showEditDialog() {
      let selectedEvent = this.selectedEvent;
      let _self = this;
      _self.employee.employee_id = selectedEvent.employee_id;
      _self.employee.start_datetime = new Date(selectedEvent.start);
      _self.employee.end_datetime = moment(selectedEvent.end).format(
        "YYYY-MM-DD"
      );
      _self.employee.hours = selectedEvent.hours;
      _self.employee.task_id = selectedEvent.tasks.map((task) => {
        return task.task_id;
      });
      _self.showAddEditPlanningDialog = true;
    },
    getEmployeePlanning() {
      let _self = this;

      _self.$axios
        .post("getplanning", {
          project_id: _self.project_id,
        })
        .then((res) => {
          let data = res.data;

          if (data.success) {
            _self.members = data.employee_planning;
            for (let i = 0; i < _self.members?.length; i++) {
              _self.categories.push(_self.members[i].employee.name);
            }
            _self.updateRange();
          } else {
            _self.snackbarError = true;
            _self.message = data.message;
          }
        });
    },
    save() {
      let _self = this;
      _self.loading = true;

      let valid_start_datetime = new Date(
        _self.employee.start_datetime
      ).setHours("10", "0", "0");
      let valid_end_datetime = new Date(_self.employee.start_datetime).setHours(
        "21",
        "0",
        "0"
      );
      let start_date = new Date(_self.employee.start_datetime);

      let month = Number(start_date.getMonth() + 1);
      month = month < 10 ? "0" + month : month;

      let dt = start_date.getDate();
      dt = dt < 10 ? "0" + dt : dt;

      _self.$axios
        .post("planning", {
          project_id: this.project_id,
          employee_id: _self.employee.employee_id,
          valid_start_datetime: valid_start_datetime,
          valid_end_datetime: valid_end_datetime,
          start_datetime: _self.employee.start_datetime,
          end_datetime: _self.employee.end_datetime,
          hours: _self.employee.hours,
          task_id: _self.employee.task_id,
          start_date: start_date.getFullYear() + "-" + month + "-" + dt,
        })
        .then((res) => {
          let data = res.data;
          _self.loading = false;
          if (data.success) {
            _self.closeDialog();
            _self.getEmployeePlanning();
            _self.showAddEditPlanningDialog = false;
            _self.errors.start_datetime = "";
          } else {
            _self.errors = {};
            if (data.errors) {
              for (let i = 0; i < data.errors?.length; i++) {
                if (data.errors[i].field == "start_datetime") {
                  if (
                    data.errors[i].invalid_start_datetime &&
                    data.errors[i].invalid_end_datetime
                  ) {
                    data.errors[i].message +=
                      moment(data.errors[i].invalid_start_datetime).format(
                        "Do MMMM YYYY hh:mm A"
                      ) +
                      " - " +
                      moment(data.errors[i].invalid_end_datetime).format(
                        "hh:mm A"
                      );
                  }
                }
                _self.errors[data.errors[i].field] = data.errors[i].message;
              }
            } else {
              _self.showAddEditPlanningDialog = true;
              _self.members = data.message;
            }
          }
        });
    },
    update() {
      let _self = this;
      _self.loading = true;

      let valid_start_datetime = new Date(
        _self.employee.start_datetime
      ).setHours("10", "0", "0");
      let valid_end_datetime = new Date(_self.employee.start_datetime).setHours(
        "21",
        "0",
        "0"
      );
      let start_date = new Date(_self.employee.start_datetime);

      let month = Number(start_date.getMonth() + 1);
      month = month < 10 ? "0" + month : month;

      let dt = start_date.getDate();
      dt = dt < 10 ? "0" + dt : dt;

      _self.$axios
        .post("/updateplanning", {
          id: _self.selectedEvent.id,
          project_id: this.project_id,
          employee_id: _self.employee.employee_id,
          valid_start_datetime: valid_start_datetime,
          valid_end_datetime: valid_end_datetime,
          start_datetime: _self.employee.start_datetime,
          end_datetime: _self.employee.end_datetime,
          hours: _self.employee.hours,
          task_id: _self.employee.task_id,
          start_date: start_date.getFullYear() + "-" + month + "-" + dt,
        })
        .then((res) => {
          let data = res.data;
          _self.loading = false;
          if (data.success) {
            _self.closeDialog();
            _self.events = [];
            _self.getEmployeePlanning();
            _self.showAddEditPlanningDialog = false;
          } else {
            _self.errors = {};
            if (data.errors) {
              for (let i = 0; i < data.errors?.length; i++) {
                if (data.errors[i].field == "start_datetime") {
                  if (
                    data.errors[i].invalid_start_datetime &&
                    data.errors[i].invalid_end_datetime
                  ) {
                    data.errors[i].message +=
                      moment(data.errors[i].invalid_start_datetime).format(
                        "Do MMMM YYYY hh:mm A"
                      ) +
                      " - " +
                      moment(data.errors[i].invalid_end_datetime).format(
                        "hh:mm A"
                      );
                  }
                }
                _self.errors[data.errors[i].field] = data.errors[i].message;
              }
            } else {
              _self.showAddEditPlanningDialog = true;
              _self.members = data.message;
            }
          }
        });
    },
    getEmployeeList() {
      if (this.cachesTeamMembers) {
        return;
      }
      let _self = this;

      _self.$axios
        .post("getmembers", {
          project_id: this.project_id,
          employee_detail_only: true,
        })
        .then((res) => {
          let data = res.data;
          if (data.success) {
            _self.setProjectTeamMembers(data.members);
            _self.updateProjectCaches("cachesTeamMembers", true);
          } else {
            _self.snackbarError = true;
            _self.message = data.message;
          }
        });
    },
    viewDay({ date }) {
      this.focus = date;
      // this.type = "day";
      this.selectedDay = date;
      this.employee.start_datetime = new Date(date);
      this.employee.end_datetime = date;
      this.showAddEditPlanningDialog = true;
    },
    viewMore({ date }) {
      this.focus = date;
      this.type = "day";
      this.selectedDay = date;
      this.employee.start_datetime = new Date(date);
      this.employee.start_datetime.setHours(0, 0, 0, 0);
      this.employee.end_datetime = date;
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = moment(new Date()).format("YYYY-MM-DD");
      this.employee.start_datetime = new Date();
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => (this.selectedOpen = true), 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange() {
      const events = [];
      this.events = [];

      for (let i = 0; i < this.members?.length; i++) {
        events.push({
          name: this.members[i].employee.name,
          start: new Date(this.members[i].start_datetime),
          end: new Date(this.members[i].end_datetime),
          color: this.members[i].employee.color
            ? this.members[i].employee.color
            : "red",
          timed: true,
          employee_id: this.members[i].employee.id,
          id: this.members[i].id,
          hours: this.members[i].hours,
          tasks: this.members[i].tasks,
          task: {
            task_id: this.members[i].task_id
              ? this.members[i].task_id.split(",").map((task) => {
                  return Number(task);
                })
              : "",
          },
        });
      }

      this.events = events;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    rndElement(arr) {
      return arr[this.rnd(0, arr?.length - 1)];
    },

    closeDialog() {
      this.showAddEditPlanningDialog = false;
      this.selectTaskDialog = false;
      this.employee.employee_id = "";

      if (this.type != "day") {
        this.selectedDay = "";
        this.employee.start_datetime = new Date();
        this.employee.end_datetime = moment(new Date()).format("YYYY-MM-DD");
      } else {
        this.employee.start_datetime = new Date(this.selectedDay);
        this.employee.end_datetime = moment(new Date()).format("YYYY-MM-DD");
      }

      this.employee.hours = "";
      this.employee.task_id = [];
      this.errors = {};
    },
    syncClickUpTasksToLocal() {
      let _self = this;

      _self.loading = true;

      _self.$axios
        .post("syncClickUpTasksToLocal", {
          project_id: this.$route.params.id,
        })
        .then((res) => {
          let data = res.data;
          _self.loading = false;
          _self.setSyncProject({
            syncProject: false,
          });

          if (!data.success) {
            (_self.snackbarError = true), (_self.message = data.message);
          }
        });
    },
    setHour(hour) {
      this.is_edit = false;
      (this.employee.employee_id = ""),
        (this.employee.start_datetime = new Date(
          this.employee.start_datetime.setHours(hour, 0, 0, 0)
        ));
      this.employee.end_datetime = moment(new Date(this.focus)).format(
        "YYYY-MM-DD"
      );
      this.employee.hours = "";
      this.employee.task_id = [];
      this.showAddEditPlanningDialog = true;
    },
    getAssignedTasks() {
      this.loadingTasks = true;

      this.$axios
        .get(
          `projects/${this.$route.params.id}/tasks/assignees/assignedTasks`,
          {
            params: {
              assignees: [this.employee.employee_id],
            },
          }
        )
        .then((response) => {
          let data = response.data;

          this.tempTasks = [];

          if (data.success) {
            this.searchResults = data.tasks;
          } else {
            this.error = true;
            this.message = data.message;
            this.searchResults = [];
          }
        })
        .finally(() => {
          this.loadingTasks = false;
        });
    },
    openAddPlanningDialog() {
      this.is_edit = false;
      this.showAddEditPlanningDialog = true;
    },
    closeSelectTaskDialog() {
      this.employee.task_id = [];
      this.selectTaskDialog = false;
    },
  },

  async mounted() {
    this.getEmployeeList();
    this.getEmployeePlanning();

    moment.locale("in");
    this.focus = moment(new Date()).format("YYYY-MM-DD");
    this.min = moment("10 AM", "HH A").valueOf();
    this.max = moment("07 PM", "HH A").valueOf();
    const d = {};
    document.addEventListener("mousedown", (e) => {
      const closestDialog = e.target.closest(".v-dialog.v-dialog--active");
      if (
        e.button === 0 &&
        closestDialog != null &&
        (e.target.classList.contains("v-card__title") ||
          e.target.classList.contains("v-tab"))
      ) {
        // element which can be used to move element
        d.el = closestDialog; // element which should be moved
        d.mouseStartX = e.clientX;
        d.mouseStartY = e.clientY;
        d.elStartX = d.el.getBoundingClientRect().left;
        d.elStartY = d.el.getBoundingClientRect().top;
        d.el.style.position = "fixed";
        d.el.style.margin = 0;
        d.oldTransition = d.el.style.transition;
        d.el.style.transition = "none";
      }
    });
    document.addEventListener("mousemove", (e) => {
      if (d.el === undefined) return;
      d.el.style.left =
        Math.min(
          Math.max(d.elStartX + e.clientX - d.mouseStartX, 0),
          window.innerWidth - d.el.getBoundingClientRect().width
        ) + "px";
      d.el.style.top =
        Math.min(
          Math.max(d.elStartY + e.clientY - d.mouseStartY, 0),
          window.innerHeight - d.el.getBoundingClientRect().height
        ) + "px";
    });
    document.addEventListener("mouseup", () => {
      if (d.el === undefined) return;
      d.el.style.transition = d.oldTransition;
      d.el = undefined;
    });
    setInterval(() => {
      // prevent out of bounds
      const dialog = document.querySelector(".v-dialog.v-dialog--active");
      if (dialog === null) return;
      dialog.style.left =
        Math.min(
          parseInt(dialog.style.left),
          window.innerWidth - dialog.getBoundingClientRect().width
        ) + "px";
      dialog.style.top =
        Math.min(
          parseInt(dialog.style.top),
          window.innerHeight - dialog.getBoundingClientRect().height
        ) + "px";
    }, 0);
  },
  directives: {
    debounce,
  },
};
</script>

<style scoped>
@import "../../assets/css/custom-calendar.css";

.h-50px {
  height: 40px !important;
}

.w-50px {
  width: 40px !important;
}

.min-height {
  height: 80vh !important;
}
</style>
<style scoped lang="scss">
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: "";
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.invalid {
  color: red !important;
}

.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: "";
  }

  &:hover::after {
    display: block;
  }
}

.custom-checkbox-input label {
  font-size: 15px !important;
  color: var(--secondary-color);
}
</style>

<style lang="scss">
.custom-toolbar .v-toolbar__content {
  padding: 0px !important;
}
.dataTimePiker .v-input__slot {
  border: 1px solid #e9edf7 !important;

  border-radius: 10px !important;
}
.dataTimePiker:hover {
  .v-input__slot {
    position: relative;
    border: 1px solid #000000 !important;
    z-index: 0;

    border-radius: 10px !important;
  }
}

.dataTimePiker .v-input__slot {
  .v-label--active {
    z-index: 100;
    color: rgba(0, 0, 0, 0.6) !important;
  }
}
.dataTimePiker
  .theme--light.v-text-field
  .v-input__control
  .v-input__slot:before {
  border: none !important;
}
.dataTimePiker {
  .theme--light.v-text-field .v-input__control .v-input__slot:after {
    border: none !important;
  }
  .v-label {
    top: 10px !important;
    left: 11px !important;
    right: auto !important;
    position: absolute !important;
    z-index: 100;
    background-color: rgb(255, 255, 255) !important;
  }
  .v-input input {
    margin-left: 10px !important;
    min-height: 40px !important;
  }
}
</style>
