<template>
  <svg :width="size" :height="size" :viewBox="`0 0 24 24`">
    <path
      d="M17.875 2.92188H15.6406V2.0625C15.6406 1.92575 15.5863 1.7946 15.4896 1.6979C15.3929 1.6012 15.2618 1.54688 15.125 1.54688C14.9882 1.54688 14.8571 1.6012 14.7604 1.6979C14.6637 1.7946 14.6094 1.92575 14.6094 2.0625V2.92188H7.39062V2.0625C7.39062 1.92575 7.3363 1.7946 7.2396 1.6979C7.1429 1.6012 7.01175 1.54688 6.875 1.54688C6.73825 1.54688 6.6071 1.6012 6.5104 1.6979C6.4137 1.7946 6.35938 1.92575 6.35938 2.0625V2.92188H4.125C3.80591 2.92188 3.49989 3.04863 3.27426 3.27426C3.04863 3.49989 2.92188 3.80591 2.92188 4.125V17.875C2.92188 18.1941 3.04863 18.5001 3.27426 18.7257C3.49989 18.9514 3.80591 19.0781 4.125 19.0781H17.875C18.1941 19.0781 18.5001 18.9514 18.7257 18.7257C18.9514 18.5001 19.0781 18.1941 19.0781 17.875V4.125C19.0781 3.80591 18.9514 3.49989 18.7257 3.27426C18.5001 3.04863 18.1941 2.92188 17.875 2.92188ZM4.125 3.95312H6.35938V4.8125C6.35938 4.94925 6.4137 5.0804 6.5104 5.1771C6.6071 5.2738 6.73825 5.32812 6.875 5.32812C7.01175 5.32812 7.1429 5.2738 7.2396 5.1771C7.3363 5.0804 7.39062 4.94925 7.39062 4.8125V3.95312H14.6094V4.8125C14.6094 4.94925 14.6637 5.0804 14.7604 5.1771C14.8571 5.2738 14.9882 5.32812 15.125 5.32812C15.2618 5.32812 15.3929 5.2738 15.4896 5.1771C15.5863 5.0804 15.6406 4.94925 15.6406 4.8125V3.95312H17.875C17.9206 3.95312 17.9643 3.97123 17.9965 4.00347C18.0288 4.0357 18.0469 4.07942 18.0469 4.125V7.04688H3.95312V4.125C3.95312 4.07942 3.97123 4.0357 4.00347 4.00347C4.0357 3.97123 4.07942 3.95312 4.125 3.95312ZM17.875 18.0469H4.125C4.07942 18.0469 4.0357 18.0288 4.00347 17.9965C3.97123 17.9643 3.95312 17.9206 3.95312 17.875V8.07812H18.0469V17.875C18.0469 17.9206 18.0288 17.9643 17.9965 17.9965C17.9643 18.0288 17.9206 18.0469 17.875 18.0469Z"
      :fill="color"
    />
  </svg>
</template>

<script>
  import IconMixin from "../../mixins/IconMixin";

  export default {
    mixins: [IconMixin],
  };
</script>
