<template>
  <div>
    <v-card tile class="pa-2 pt-3" flat>
      <v-card-title class="pa-4 pt-1">
        <span class="span_text">Team List</span>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          dense
          outlined
          class="mr-4 border-radius-6"
          :style="{ maxWidth: '300px' }"
        ></v-text-field>
        <v-btn
          color="primary"
          dark
          class="px-3 border-radius-6 custom-data-table-action-btn"
          @click="openAddMemberDialog()"
        >
          <v-icon class="mr-1"> mdi-plus </v-icon>
          Add
        </v-btn>
      </v-card-title>
      <v-data-table
        :loading="loading"
        :headers="headersList"
        :items="projectTeamMembers"
        :page.sync="currentPage"
        :search="search"
        :items-per-page="15"
        class="custom-data-table mx-4"
      >
        <template v-slot:[`item.name`]="{ item }">
          <v-avatar size="36px" class="mr-3" v-if="item.employee?.profile_url">
            <v-img alt="Avatar" :src="item?.employee?.profile_url"></v-img>
          </v-avatar>
          <v-avatar
            v-else
            size="36px"
            class="mr-3 white--text"
            color="secondary"
          >
            {{ item.employee?.intials }}
          </v-avatar>
          {{ item.employee?.name }}
        </template>

        <!-- active -->
        <template
          v-slot:[`item.active`]="{ item }"
          v-if="user.role === 'admin'"
        >
          <v-switch
            v-model="item.employee.active"
            :value="item?.employee?.active"
            @change="disableEmployeeListing(item)"
            color="green"
            inset
          >
          </v-switch>
        </template>

        <!-- action links -->
        <template
          v-slot:[`item.actions`]="{ item }"
          v-if="user.role === 'admin'"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                depressed
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                :to="`/projects/${$route.params.id}/planning`"
              >
                <CalendarBlankIcon />
              </v-btn>
            </template>
            <span>View Planning</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                @click="editItem(item)"
                class="cursor-pointer mr-3"
                v-bind="attrs"
                v-on="on"
              >
                <PencilIcon :size="19" />
              </span>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                @click.prevent="setDelete(item.id)"
                class="cursor-pointer"
                v-bind="attrs"
                v-on="on"
              >
                <TrashIcon :size="19" />
              </span>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <!-- confirmation dialog for deleting employee -->
      <v-dialog v-model="dialogDelete" max-width="450px">
        <v-card>
          <v-card-title class="headline">Alert</v-card-title>
          <v-card-title class="subtitle-1 mb-4"
            >Are you sure you want to Remove this Employee ?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDelete"
              :disabled="isDeletingMember"
              >Cancel</v-btn
            >
            <v-btn
              color="red darken-1"
              text
              @click="removemember"
              :loading="isDeletingMember"
              >Delete</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>

    <Dialog
      :isOpen="showEmployeeAddEditDialog"
      :save="updateEmp"
      @close="showEmployeeAddEditDialog = false"
      title="Edit Employee"
      :action="true"
    >
      <template>
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-text-field
              label="Employee Name
"
              name="name"
              v-model="emp.name"
              :error="errors.name != null"
              :error-messages="errors.name"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-text-field
              label="Email"
              name="email"
              v-model="emp.email"
              :error="errors.email != null"
              :error-messages="errors.email"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-select
              :items="roles"
              v-model="emp.role_id"
              :error="errors.role_id != null"
              :error-messages="errors.role_id"
              item-text="name"
              item-value="role_id"
              label="Role"
              name="role_id"
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" class="py-0 mx-0">
            <v-checkbox
              class="mt-0"
              v-model="invited"
              label="Send Invitation"
            ></v-checkbox>
          </v-col>
        </v-row>
      </template>
    </Dialog>

    <Dialog
      :isOpen="showAddMemberDialog"
      :save="addMemberToTeam"
      @close="
        showAddMemberDialog = false;
        addNewEmployeeDialog = false;
      "
      title="Add Team Member"
      :action="!addNewEmployeeDialog"
    >
      <template>
        <template v-if="!addNewEmployeeDialog">
          <v-row>
            <v-col cols="12" class="mb-3 d-flex">
              <v-autocomplete
                v-model="employee_id"
                class="pr-3"
                label="Select Employees"
                dense
                chips
                deletable-chips
                multiple
                small-chips
                outlined
                :items="employeesList"
                item-text="name"
                item-value="id"
                @input="searchInput = null"
                :search-input.sync="searchInput"
              ></v-autocomplete>
              <v-btn
                v-if="this.user.role === 'admin'"
                class="primary"
                depressed
                height="40"
                width="40"
                icon
                color="white"
                @click="openAddEmployeeDialog()"
              >
                <v-icon dark> mdi-plus </v-icon>
              </v-btn>
            </v-col>

            <v-col cols="12" class="py-0">
              <v-select
                v-model="employee_role"
                :items="['Member', 'Manager']"
                outlined
                dense
                label="Select Role"
              ></v-select>
            </v-col>
          </v-row>
        </template>

        <template v-else-if="addNewEmployeeDialog">
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                label="Full Name"
                name="name"
                v-model="item.name"
                :error="errors.name != null"
                :error-messages="errors.name"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                label="Email"
                name="email"
                v-model="item.email"
                :error="errors.email != null"
                :error-messages="errors.email"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-select
                :items="roles"
                v-model="item.role_id"
                :error="errors.role_id != null"
                :error-messages="errors.role_id"
                item-text="name"
                item-value="role_id"
                label="Role"
                name="role_id"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" class="py-0 mx-0">
              <v-checkbox
                class="mt-0"
                v-model="invited"
                label="Send Invitation"
              ></v-checkbox>
            </v-col>
          </v-row>
        </template>
      </template>

      <template v-if="addNewEmployeeDialog">
        <div style="display: flex; justify-content: center; gap: 20px">
          <v-btn
            color="secondary"
            width="100px"
            @click.prevent="addNewEmployeeDialog = false"
          >
            Close
          </v-btn>
          <v-btn color="primary" @click.prevent="submit" width="100px">
            Save
          </v-btn>
        </div>
      </template>
    </Dialog>

    <!-- for success message -->
    <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      color="green accent-4"
      align-baseline="center"
    >
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>

    <!-- for error message -->
    <v-snackbar
      v-model="snackbarError"
      :timeout="3000"
      color="deep-orange accent-4"
      align-baseline="center"
    >
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import PencilIcon from "../../assets/icons/PencilIcon.vue";
import TrashIcon from "../../assets/icons/TrashIcon.vue";
import Dialog from "../common/basic/Dialog.vue";
import CalendarBlankIcon from "../../assets/icons/CalendarBlankIcon.vue";

export default {
  name: "ProjectTeam",
  props: ["project_id", "selected", "value"],
  data: () => ({
    loading: false,
    user: {},
    snackbar: false,
    snackbarError: false,
    employee_id: [],
    employee_role: "Member",
    message: "",
    members: [],
    errors: [],
    dialogDelete: false,
    deleteingId: "",
    searchInput: null,
    showAddMemberDialog: false,
    isSavingMembers: false,
    isLoadingMembers: false,
    isDeletingMember: false,
    search: "",
    currentPage: 1,
    roles: [],
    isAdmin: false,
    addNewEmployeeDialog: false,
    showEmployeeAddEditDialog: false,
    emp: {},
    item: {
      name: "",
      email: "",
      password: "",
      role_id: "",
    },
    invited: true,
    headers: [
      {
        text: "Team Name",
        align: "start",
        value: "name",
        sortable: false,
      },
      {
        text: "Designation ",
        value: "employee_role",
        sortable: false,
      },
    ],
  }),
  components: {
    TrashIcon,
    PencilIcon,
    Dialog,
    CalendarBlankIcon,
  },
  computed: {
    ...mapState("tasks", ["syncProject"]),
    ...mapState("projects", ["projectTeamMembers", "cachesTeamMembers"]),
    ...mapState("employees", ["employeesList", "cachesEmpData"]),

    headersList() {
      let data = this.headers;
      if (this.user.role === "admin") {
        data.push(
          {
            text: "Active / Inactive",
            value: "active",
            sortable: false,
          },
          { text: "Actions", value: "actions", sortable: false }
        );
      }
      return data;
    },
  },

  methods: {
    ...mapMutations("projects", [
      "setProjectTeamMembers",
      "updateProjectCaches",
    ]),
    ...mapMutations("employees", ["setEmployees", "isCachesEmpData"]),

    ...mapMutations("tasks", ["setSyncProject"]),
    getEmployeeList() {
      if (this.cachesEmpData) {
        return;
      }
      let _self = this;
      this.isLoadingMembers = true;

      this.$axios
        .post("/employeeList", {
          project_id: _self.project_id,
        })
        .then((res) => {
          let data = res.data;

          if (!data.success) {
            _self.snackbarError = true;
            _self.message = data.message;
          } else {
            _self.setEmployees(data.employees);
            _self.isCachesEmpData(true);
          }
        })
        .finally(() => {
          this.isLoadingMembers = false;
        });
    },
    addMemberToTeam() {
      let _self = this;

      if (!this.employee_id || !this.cachesTeamMembers) {
        this.errors = {
          employee_id: "Please Select Members",
        };
        return;
      }

      this.isSavingMembers = true;

      _self.$axios
        .post("/addmembertoteam", {
          project_id: _self.project_id,
          employee_id: _self.employee_id,
          employee_role: _self.employee_role,
        })
        .then((res) => {
          let resdata = res.data;

          if (resdata.success) {
            this.closeAddMemberDialog();
            _self.snackbar = true;
            _self.message = resdata.message;
            this.updateProjectCaches({
              key: "cachesTeamMembers",
              value: false,
            });

            this.$emit("refreshTeamMembersList");
          } else {
            if (resdata.errors) {
              for (let i = 0; i < resdata.errors.length; i++) {
                _self.errors[resdata.errors[i].field] =
                  resdata.errors[i].message;
              }
            } else {
              _self.snackbarError = true;
              _self.message = resdata.message;
            }
          }
          _self.employee_id = [];
        })
        .finally(() => {
          this.isSavingMembers = false;
        });
    },
    removemember() {
      let _self = this;
      this.isDeletingMember = true;

      _self.$axios
        .delete("/removemember/" + _self.deleteingId)
        .then((res) => {
          let resdata = res.data;

          if (resdata.success) {
            _self.closeDelete();
            _self.snackbar = true;
            _self.message = resdata.message;
            this.updateProjectCaches({
              key: "cachesTeamMembers",
              value: false,
            });

            this.$emit("refreshTeamMembersList");
          } else {
            _self.snackbarError = true;
            _self.message = resdata.message;
          }
        })
        .finally(() => {
          this.isDeletingMember = false;
        });
    },

    setDelete(id) {
      this.deleteingId = id;
      this.dialogDelete = true;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.deleteingId = "";
    },
    getEmployeeDetail(id) {
      this.$axios.get("employee/" + id).then((res) => {
        if (res.data.success) {
          this.emp = res.data.employee;
        } else {
          this.snackbarError = true;
          this.message = res.data.message;
        }
      });
    },
    editItem(item) {
      this.form_type_insert = false;
      this.editedIndex = this.employeesList.indexOf(item);
      this.getEmployeeDetail(item.employee_id);
      this.showEmployeeAddEditDialog = true;
      this.errors = [];
    },
    disableEmployeeListing(item) {
      let _self = this;
      this.$axios
        .post("/disableEmployeeListing", {
          employee_id: item.employee_id,
        })
        .then((res) => {
          let data = res.data;

          if (!data.success) {
            _self.snackbarError = true;
            _self.message = data.message;
          } else {
            _self.snackbar = true;
            _self.message = data.message;
          }
        });
    },
    updateEmp() {
      this.loading = true;
      this.$axios.put("/employee/" + this.emp.id, this.emp).then((res) => {
        let _self = this;
        let data = res.data;

        if (!data.success) {
          _self.errors = [];
          if (data.errors) {
            for (let i = 0; i < data.errors.length; i++) {
              _self.errors[data.errors[i].field] = data.errors[i].message;
            }
          }

          _self.showEmployeeAddEditDialog = false;
        } else {
          _self.close();
          _self.snackbar = true;
          _self.message = data.message;
          this.updateProjectCaches({ key: "cachesTeamMembers", value: false });

          this.$emit("refreshTeamMembersList");
        }
        _self.loading = false;
      });
    },
    openAddMemberDialog() {
      this.errors = [];
      this.employee_id = [];
      this.showAddMemberDialog = true;
    },
    closeAddMemberDialog() {
      this.showAddMemberDialog = false;
      this.errors = [];
      this.employee_id = [];
    },

    getRoles() {
      let _self = this;

      this.$axios.post("roles").then((res) => {
        let resdata = res.data;

        if (resdata.success) {
          _self.roles = resdata.roles;
          this.item.role_id = this.roles[0].role_id;
        } else {
          _self.snackbarError = true;
          _self.message = resdata.message;
        }
      });
    },
    openAddEmployeeDialog() {
      // this.showAddMemberDialog = false;
      this.addNewEmployeeDialog = true;
      this.errors = [];
      this.item.role_id = this.roles[0].role_id;
    },

    submit() {
      let postData = this.item;
      postData.is_invited = this.invited;
      this.$axios.post("/employee", postData).then((res) => {
        let _self = this;
        let data = res.data;

        if (!data.success) {
          _self.errors = [];
          if (data.errors) {
            for (let i = 0; i < data.errors.length; i++) {
              _self.errors[data.errors[i].field] = data.errors[i].message;
            }
          } else {
            _self.snackbarError = true;
            _self.message = data.message;
          }
          _self.showEmployeeAddEditDialog = false;
        } else {
          _self.snackbar = true;
          _self.message = data.message;
          _self.getEmployeeList();
          _self.close();

          this.employee_id = [data.employee.id];
        }
      });
    },

    close() {
      this.item.name = "";
      this.item.email = "";
      this.item.password = "";
      this.item.role_id = "";
      this.showAddMemberDialog = false;
      this.addNewEmployeeDialog = false;
      this.showEmployeeAddEditDialog = false;
    },
  },
  created() {
    let user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      this.isAdmin = user.role == "admin";
    }
  },
  mounted() {
    let localUser = localStorage.getItem("user");
    this.user = JSON.parse(localUser);
    this.getEmployeeList();
    this.$emit("refreshTeamMembersList");
    if (this.user.role === "admin") {
      this.getRoles();
    }
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer !important;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

.member-item:not(:last-child) {
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 3px;
}

.member-item .delete-btn {
  display: none;
}

.member-item:hover .delete-btn {
  display: block;
}
.span_text {
  font-family: Roboto;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 12px;
  text-align: left;
}
</style>

<style>
.team-member-select {
  color: var(--primary-font-color) !important;
}

.team-member-select fieldset {
  border: 3px solid var(--border-color) !important;
  border-radius: 6px 6px 0px 0px !important;
}

.team-member-select .v-label {
  top: 16px !important;
}

.team-member-select .v-select__slot {
  padding: 6px 0px !important;
}

.selected-team-member-container {
  border-top: none;
  border: 2px solid var(--border-color) !important;
  background: #f0f4f8;
  border-radius: 0px 0px 6px 6px !important;
}

.add-team-float-btn {
  position: fixed;
  bottom: 10px;
  right: 20px;
}
</style>
