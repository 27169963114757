<template>
  <v-navigation-drawer :value="value" hide-overlay fixed right :temporary="!permanent" :permanent="permanent"
    :class="{ 'custom-sidebar': true, fullscreen: !!fullscreen }" :width="width" :stateless="stateless"
    @input="toggleDrawer">
    <v-card flat :class="{ 'drawer-container': true, fullscreen: !!fullscreen }">
      <v-card-title>
        <div class="drawer-title full-width">
          <div>
            <slot name="title"></slot>
          </div>
          <div class="ml-2">
            <slot name="close-btn">
              <v-icon class="cursor-pointer" @click="$emit('input', false); $emit('close')">
                mdi-close
              </v-icon>
            </slot>
          </div>
        </div>
        <slot name="header"></slot>
      </v-card-title>
      <div class="drawer-content custom_scrollbar">
        <v-card-text class="py-2">
          <slot name="content"></slot>
        </v-card-text>
        <v-card-actions class="mb-2 mx-2" :class="footer_class">
          <slot name="footer"></slot>
        </v-card-actions>
      </div>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    permanent: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
    },
    width: {
      type: String,
      default: "400px"
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    stateless: {
      type: Boolean,
      default: false
    },
    footer_class: {
      type: String,
      default: ""
    }
  },
  watch: {
    value(newValue) {
      if (newValue) {
        document.body.classList.add("custom-sidebar-open");
      } else {
        document.body.classList.remove("custom-sidebar-open");
      }
    },
  },
  methods: {
    toggleDrawer(isOpened) {
      this.$emit('input', isOpened)

      if (!isOpened) {
        this.$emit('close')
      }
    }
  },
  mounted() {
    if (this.value) {
      document.body.classList.add("custom-sidebar-open");
    }
  }
};
</script>

<style scoped>
.custom-sidebar {
  top: 75px !important;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.08) !important;
  overflow-y: auto;
  z-index: 90;
  height: calc(100vh - 75px) !important;
}

.custom-sidebar.fullscreen {
  top: 0px !important;
  height: 100vh !important;
}

.drawer-container {
  display: grid !important;
  grid-template-rows: auto 1fr;
  min-height: 100%;
  padding: 5px;
  height: calc(100vh - 75px) !important;
}

.drawer-container.fullscreen {
  height: 100vh !important;
}

.drawer-title {
  position: sticky;
  top: 0px;
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.drawer-title {
  font-size: 18px !important;
  line-height: 1.5;
}

.drawer-content {
  overflow-y: auto;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
}
</style>

<style>
body.custom-sidebar-open {
  height: 100vh;
  overflow: hidden;
}
</style>