<template>
  <editor-content
    :editor="editor"
    class="comment-editor custom_scrollbar"
    :style="{ ...styles, height: height }"
    @keyup.native="handleSubmit"
  />
</template>

<script>
import Mention from "@tiptap/extension-mention";
import Placeholder from "@tiptap/extension-placeholder";
import StarterKit from "@tiptap/starter-kit";
import { Editor, EditorContent } from "@tiptap/vue-2";
import suggestion from "./suggestion";

export default {
  components: {
    EditorContent,
  },

  props: {
    value: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "110px",
    },
    styles: {
      type: Object,
      default: () => {},
    },
    persons: {
      type: Array,
      default: () => [],
    },
    isComment: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      editor: null,
    };
  },

  watch: {
    value(value) {
      const isSame = this.editor.getHTML() === value;

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(value, false);
    },
  },
  methods: {
    handleSubmit(event) {
      if (
        event.shiftKey &&
        event.ctrlKey &&
        event.key === "Enter" &&
        this.isComment
      ) {
        this.$emit("storeComment");
        // Custom behavior for Shift-Control-Enter shortcut
      }
    },
  },

  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit,
        Mention.configure({
          HTMLAttributes: {
            class: "mention",
          },
          suggestion: {
            ...suggestion,
            items: ({ query }) => {
              return this.persons
                .filter((item) =>
                  item.label.toLowerCase().startsWith(query.toLowerCase())
                )
                .slice(0, 5);
            },
          },
          renderLabel({ options, node }) {
            return `${options.suggestion.char}${
              node.attrs.label ?? node.attrs.id
            }`;
          },
        }),
        Placeholder.configure({
          placeholder: "Write Comment Here",
        }),
      ],
      content: "",
      onUpdate: () => {
        this.$emit("input", this.editor.getHTML());
      },
    });
  },

  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>

<style scoped>
.comment-editor {
  border: 2px solid #e5e5e5;
  border-radius: 4px;
  overflow-y: auto;
  text-align: left;
  border-radius: 10px !important;
}

.comment-editor:focus-visible {
  border: 2px solid #2b81d6;
}
</style>

<style>
.comment-editor .ProseMirror {
  height: calc(100% - 10px);
  padding: 10px;
}

.comment-editor .ProseMirror p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.comment-editor .ProseMirror > * + * {
  margin-top: 0.75em;
}

.comment-editor .ProseMirror:focus-visible {
  outline: none;
}

.comment-editor .mention {
  padding: 0.1rem;
  color: #0051b5;
  font-weight: bold;
  font-size: 14px;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  box-decoration-break: clone;
}
</style>
