<template>
  <svg :width="size" :height="size" :viewBox="`0 0 24 24`">
    <path
      d="M17.9531 11.5625H4.20312C3.88404 11.5625 3.57802 11.6893 3.35239 11.9149C3.12676 12.1405 3 12.4465 3 12.7656V16.2031C3 16.5222 3.12676 16.8282 3.35239 17.0539C3.57802 17.2795 3.88404 17.4062 4.20312 17.4062H17.9531C18.2722 17.4062 18.5782 17.2795 18.8039 17.0539C19.0295 16.8282 19.1562 16.5222 19.1562 16.2031V12.7656C19.1562 12.4465 19.0295 12.1405 18.8039 11.9149C18.5782 11.6893 18.2722 11.5625 17.9531 11.5625ZM18.125 16.2031C18.125 16.2487 18.1069 16.2924 18.0747 16.3247C18.0424 16.3569 17.9987 16.375 17.9531 16.375H4.20312C4.15754 16.375 4.11382 16.3569 4.08159 16.3247C4.04936 16.2924 4.03125 16.2487 4.03125 16.2031V12.7656C4.03125 12.72 4.04936 12.6763 4.08159 12.6441C4.11382 12.6119 4.15754 12.5938 4.20312 12.5938H17.9531C17.9987 12.5938 18.0424 12.6119 18.0747 12.6441C18.1069 12.6763 18.125 12.72 18.125 12.7656V16.2031ZM17.9531 4H4.20312C3.88404 4 3.57802 4.12676 3.35239 4.35239C3.12676 4.57802 3 4.88404 3 5.20312V8.64062C3 8.79862 3.03112 8.95507 3.09158 9.10104C3.15205 9.24701 3.24067 9.37964 3.35239 9.49136C3.46411 9.60308 3.59674 9.6917 3.74271 9.75217C3.88868 9.81263 4.04513 9.84375 4.20312 9.84375H17.9531C18.1111 9.84375 18.2676 9.81263 18.4135 9.75217C18.5595 9.6917 18.6921 9.60308 18.8039 9.49136C18.9156 9.37964 19.0042 9.24701 19.0647 9.10104C19.1251 8.95507 19.1562 8.79862 19.1562 8.64062V5.20312C19.1562 4.88404 19.0295 4.57802 18.8039 4.35239C18.5782 4.12676 18.2722 4 17.9531 4ZM18.125 8.64062C18.125 8.68621 18.1069 8.72993 18.0747 8.76216C18.0424 8.79439 17.9987 8.8125 17.9531 8.8125H4.20312C4.15754 8.8125 4.11382 8.79439 4.08159 8.76216C4.04936 8.72993 4.03125 8.68621 4.03125 8.64062V5.20312C4.03125 5.15754 4.04936 5.11382 4.08159 5.08159C4.11382 5.04936 4.15754 5.03125 4.20312 5.03125H17.9531C17.9987 5.03125 18.0424 5.04936 18.0747 5.08159C18.1069 5.11382 18.125 5.15754 18.125 5.20312V8.64062Z"
      :fill="color"
    />
  </svg>
</template>

<script>
import IconMixin from "../../mixins/IconMixin";

export default {
  mixins: [IconMixin],
};
</script>