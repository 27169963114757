<template>
  <v-tooltip bottom :open-delay="100">
    <template v-slot:activator="{ on, attrs }">
      <div class="avatar-wrapper">
        <v-avatar
          :color="color"
          class="avatar"
          :size="size"
          v-bind="attrs"
          v-on="on"
        >
          <img v-if="profile_url" :src="profile_url" class="profile-img" />
          <span v-else class="intials">{{ intials }}</span>
        </v-avatar>
        <div class="badge" @click="$emit('removeAssignee')">
          <v-icon size="10" color="white" style="cursor-pointer;">mdi-close</v-icon>
        </div>
      </div>
    </template>
    <span>{{ name || "Info Not Available" }}</span>
  </v-tooltip>
</template>

<script>
const defaultValues = {
  color: "grey lighten-2",
  size: "40px",
  profile_url: null,
  intials: "U",
  name: "",
};

export default {
  props: {
    color: {
      type: String,
      default: defaultValues.color,
    },
    size: {
      type: String,
      default: defaultValues.size,
    },
    profile_url: {
      type: String,
      default: defaultValues.profile_url,
    },
    intials: {
      type: String,
      default: defaultValues.intials,
    },
    name: {
      type: String,
      default: defaultValues.name,
    },
  },
};
</script>

<style scoped>
.avatar {
  color: white;
  cursor: pointer;
  border: 2px solid white !important;
  background: #e5e5e5;
}

.avatar-wrapper {
  position: relative;
  display: inline-block;
}

.avatar-wrapper:hover {
  z-index: 99;
  border-radius: 50%;
}

.avatar-wrapper:hover .badge {
  display: flex;
}

.badge {
  position: absolute;
  top: -6px;
  right: -2px;
  background: grey;
  height: 16px;
  width: 16px;
  color: white;
  display: none;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50%;
  border: 2px solid white;
  padding: 3px;
  z-index: 100;
  cursor: pointer;
}

.intials {
  font-size: 11px;
}

.profile-img {
  object-fit: cover;
}
</style>