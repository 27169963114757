<template>
  <svg :width="size" :height="size" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="17" cy="17" r="16.75" fill="white" :stroke="color" stroke-width="0.5" stroke-dasharray="2 2"/>
<path d="M9.17875 10.4926C9.1228 10.5409 9.07788 10.6006 9.04703 10.6678C9.01619 10.735 9.00015 10.808 9 10.8819V25.328C9 25.4647 9.05432 25.5959 9.15102 25.6926C9.24772 25.7893 9.37887 25.8436 9.51562 25.8436C9.65238 25.8436 9.78353 25.7893 9.88023 25.6926C9.97693 25.5959 10.0312 25.4647 10.0312 25.328V21.4419C12.4444 19.4834 14.4897 20.4966 16.8495 21.6627C18.2348 22.3502 19.7276 23.0876 21.3183 23.0876C22.4845 23.0876 23.7039 22.6923 24.9758 21.5906C25.0318 21.5426 25.0769 21.4832 25.1081 21.4163C25.1392 21.3495 25.1556 21.2767 25.1562 21.203V10.8819C25.1564 10.783 25.128 10.6861 25.0746 10.6029C25.0212 10.5197 24.945 10.4535 24.855 10.4124C24.7651 10.3713 24.6652 10.3569 24.5673 10.371C24.4694 10.385 24.3776 10.4269 24.3029 10.4917C21.8107 12.6505 19.7241 11.6166 17.3067 10.4195C14.9099 9.23274 12.1797 7.89641 9.17875 10.4926ZM24.125 20.9598C21.7119 22.9191 19.6666 21.9051 17.3067 20.7381C15.1359 19.663 12.703 18.459 10.0312 20.1717V11.1242C12.4444 9.16485 14.4897 10.1789 16.8495 11.3442C19.0203 12.4193 21.4532 13.6233 24.125 11.914V20.9598Z" :fill="color"/>
</svg>
</template>

<script>
import IconMixin from "../../mixins/IconMixin"

export default {
  mixins: [IconMixin],
  props: {
    color: {
      type: String,
      default: '#787486'
    },
  }
};
</script>
