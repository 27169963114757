const activityLogModuleIdentifiers = {
  GENERAL: "GENERAL",
  STATUS: "STATUS",
  ASSIGNEE: "ASSIGNEE",
  PRIORITY: "PRIORITY",
};

module.exports = {
  activityLogModuleIdentifiers,
};
